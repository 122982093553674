import React, { useState } from 'react';
import { Button, ButtonEmpty, ContextMenu, ContextMenuItem, EuiText } from 'ui';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { useSelection } from '@app/contexts/SelectionContext/SelectionContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { usePropertiesPageContext } from '@app/cx/Properties/PropertiesPage.context';
import { AddPropertyFlyout } from '@app/cx/Stream/AddPropertyFlyout/AddPropertyFlyout';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_SIMPLIFIED_SOV } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { DeletePropertyModal } from '../DeletePropertyModal/DeletePropertyModal';
import { DisposePropertyModal } from '../DisposePropertyModal/DisposePropertyModal';
import { usePropertiesDataGridContext } from '../PropertiesDataGrid/context/PropertiesDataGridContext';
import { sendMixpanelEvent } from '../PropertiesDataGrid/utils';
import { IGroupedProps } from '../PropertiesGroupedList/types';

export const SelectionButton = ({ group }: { group: IGroupedProps }) => {
  const { refetch, refetchGroups, refetchSelectedRowProperties, attributeLocks } =
    usePropertiesDataGridContext();
  const { selectedOrganization } = useUserSession();

  const isSimplifiedSOVEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SIMPLIFIED_SOV,
  );
  const { stream, permissions } = isSimplifiedSOVEnabled
    ? usePropertiesPageContext()
    : useStreamContext();
  const { selectedRows } = useSelection();
  const selectedRowsCount = Object.values(selectedRows).length;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDisposePropertyModalOpen, setIsDisposePropertyModalOpen] = useState(false);
  const [isDeletePropertyModalOpen, setIsDeletePropertyModalOpen] = useState(false);
  const { showFlyout } = useFlyout();
  const tracker = useTracker();

  const disableEdit = Object.values(selectedRows).some((row) => {
    const attributeLock = attributeLocks?.find(
      (lock) => lock?.propertyArchipelagoID === row?.archipelagoId,
    );
    // want to disable edit if there are any selected rows that are fully locked
    return !!attributeLock && attributeLock.allAttributesLocked;
  });

  // to implement on click functionality
  const PropertyCount = (
    <EuiText size="s" style={{ display: 'inline-block', marginLeft: '8px' }}>
      <b>{group?.filteredPropertyCount}</b> Properties
    </EuiText>
  );

  if (selectedRowsCount > 0) {
    const menuItems: ContextMenuItem[] = [
      {
        disabled: disableEdit,
        iconName: 'edit3',
        label: `Edit (${selectedRowsCount})`,
        onClick: () => {
          // FIX ME
          // @ts-ignore
          sendMixpanelEvent(tracker, 'Open edit property flyout', stream);
          setIsPopoverOpen(false);
          showFlyout(
            <AddPropertyFlyout
              selectedRows={selectedRows}
              refetch={refetch}
              refetchGroups={refetchGroups}
              refetchSelected={refetchSelectedRowProperties}
              attributeLocks={attributeLocks}
            />,
          );
        },
        toolTipContent: disableEdit
          ? 'One or more of the selected rows are being edited by another user.'
          : undefined,
        toolTipTitle: disableEdit ? 'Cannot Edit Properties' : undefined,
      },
      {
        iconName: 'minusCircle',
        label: `Dispose (${selectedRowsCount})`,
        onClick: () => {
          // FIX ME
          // @ts-ignore
          sendMixpanelEvent(tracker, 'Open dispose property modal', stream);
          setIsPopoverOpen(false);
          setIsDisposePropertyModalOpen(true);
        },
      },
    ];
    if (permissions.canDeleteProperties) {
      menuItems.push({
        iconName: 'trash2',
        label: `Delete (${selectedRowsCount})`,
        onClick: () => {
          // FIX ME
          // @ts-ignore
          sendMixpanelEvent(tracker, 'Open delete property modal', stream);
          setIsPopoverOpen(false);
          setIsDeletePropertyModalOpen(true);
        },
      });
    }

    const ButtonComponent = isSimplifiedSOVEnabled ? Button : ButtonEmpty;
    return (
      <>
        {isSimplifiedSOVEnabled && PropertyCount}
        <ContextMenu
          anchorPosition="upCenter"
          panelPaddingSize="s"
          button={
            // FIX ME
            // @ts-ignore
            <ButtonComponent
              label={`${selectedRowsCount} ${selectedRowsCount > 1 ? 'items' : 'item'} selected`}
              size="xs"
              iconName={isSimplifiedSOVEnabled ? 'chevronDown' : 'arrowDown'}
              iconSide="right"
              onClick={() => {
                sendMixpanelEvent(
                  tracker,
                  `${isPopoverOpen ? 'Close' : 'Open'} selection menu`,
                  // FIX ME
                  // @ts-ignore
                  stream,
                );
                setIsPopoverOpen(!isPopoverOpen);
              }}
              fill={isSimplifiedSOVEnabled ? true : undefined}
              style={isSimplifiedSOVEnabled ? { marginLeft: '8px' } : undefined}
            />
          }
          onClose={() => {
            // FIX ME
            // @ts-ignore
            sendMixpanelEvent(tracker, 'Close selection menu', stream);
            setIsPopoverOpen(false);
          }}
          open={isPopoverOpen}
          panel={{
            items: menuItems,
          }}
        />
        {isDisposePropertyModalOpen && (
          <DisposePropertyModal
            onClose={() => {
              setIsDisposePropertyModalOpen(false);
            }}
            refetch={refetch}
            refetchGroups={refetchGroups}
          />
        )}
        {isDeletePropertyModalOpen && (
          <DeletePropertyModal
            onClose={() => {
              setIsDeletePropertyModalOpen(false);
            }}
            refetch={refetch}
            refetchGroups={refetchGroups}
          />
        )}
      </>
    );
  }
  return isSimplifiedSOVEnabled ? PropertyCount : null;
};
