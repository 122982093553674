import React, { useContext, useEffect, useState } from 'react';
import { EPropertySelectType, IJob } from '@onarchipelago/dice/EnrichmentProjects/types';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import AttributePicker from './AttributePicker';
import { Flyout } from './Flyout.emotion';
import PropertyPicker from './PropertyPicker';
import SelectData from './SelectData';
import UploadData from './UploadData';
import useStartEdit from './useStartEdit';

type FlyoutStatus = 'attributePicker' | 'selectData' | 'propertyPicker' | 'uploadData';

interface Props {
  filterable: Array<PropertyAttributeMetadata>;
  inProgressJob?: IJob;
  orgName: string;
  stream: IGraphQLStream;
  jobType: EJobType;
  refetch: () => void;
  attributes?: IGraphQLAttributeMetadata[];
  properties?: IGraphQLProperty[];
}

const StartEdit: React.FC<Props> = ({
  filterable,
  orgName,
  stream,
  jobType,
  refetch,
  attributes = [],
  properties = [],
}) => {
  const [flyoutStatus, setFlyoutStatus] = useState<FlyoutStatus>('selectData');
  const [httpURL, setHttpURL] = useState<string>('');
  const [jobID, setJobID] = useState<string>('');
  const [inProgressItems, setInProgressItems] = useState<string>();
  const [selectedAttributes, setSelectedAttributes] =
    useState<IGraphQLAttributeMetadata[]>(attributes);
  const [selectedProperties, setSelectedProperties] = useState<IGraphQLProperty[]>(properties);

  const { closeFlyout } = useContext(FlyoutContext);
  const tracker = useTracker();
  const { handleStart, isLoading } = useStartEdit({
    jobID,
    jobType,
    nSelectedProperties: selectedProperties?.length,
    orgName,
    selectedAttributes,
    selectedProperties,
    setHttpURL,
    setInProgressItems,
    setJobID,
    stream,
    successCallback: () => setFlyoutStatus('uploadData'),
  });

  const handleUpdateSelectedAttributes = (updatedAttributes: IGraphQLAttributeMetadata[]) => {
    setSelectedAttributes([...updatedAttributes]);
    setInProgressItems(undefined);
  };

  const handleUpdateSelectedProperties = (updatedProperties: IGraphQLProperty[]) => {
    setSelectedProperties([...updatedProperties]);
    setInProgressItems(undefined);
  };

  useEffect(() => {
    switch (flyoutStatus) {
      case 'propertyPicker':
        sendMixpanelEvent(
          tracker,
          'Edit properties with an Excel worksheet: Open property picker',
          stream,
        );
        break;
      case 'attributePicker':
        sendMixpanelEvent(
          tracker,
          'Edit properties with an Excel worksheet: Open attribute picker',
          stream,
        );
    }
  }, [flyoutStatus]);

  const resetFlyout = () => {
    sendMixpanelEvent(
      tracker,
      'Edit properties with an Excel worksheet: return to main flyout',
      stream,
    );
    setFlyoutStatus('selectData');
  };

  return (
    <Flyout ownFocus onClose={closeFlyout} aria-labelledby="flyoutTitle" size="m">
      {flyoutStatus === 'selectData' && (
        <SelectData
          handleSelectAttributes={() => setFlyoutStatus('attributePicker')}
          handleSelectProperties={() => setFlyoutStatus('propertyPicker')}
          inProgressItems={inProgressItems}
          isLoading={isLoading}
          nSelectedAttributes={selectedAttributes?.length}
          nSelectedProperties={selectedProperties?.length}
          onSubmit={handleStart}
          jobType={jobType}
        />
      )}
      {flyoutStatus === 'attributePicker' && (
        <AttributePicker
          handleClose={resetFlyout}
          handleUpdateSelectedAttributes={handleUpdateSelectedAttributes}
          streamSlug={stream?.slug}
          stream={stream}
          selectedAttributes={selectedAttributes}
          jobType={jobType}
        />
      )}
      {flyoutStatus === 'propertyPicker' && (
        <PropertyPicker
          filterable={filterable}
          handleClose={resetFlyout}
          handleUpdateSelectedProperties={handleUpdateSelectedProperties}
          orgName={orgName}
          selectedProperties={selectedProperties}
          propertySelectType={
            jobType === EJobType.EditLosses
              ? EPropertySelectType.AllPropertiesWithLosses
              : EPropertySelectType.AllProperties
          }
        />
      )}
      {flyoutStatus === 'uploadData' && (
        <UploadData
          jobID={jobID}
          nSelectedAttributes={selectedAttributes?.length}
          nSelectedProperties={selectedProperties?.length}
          stream={stream}
          worksheetLink={httpURL}
          jobType={jobType}
          refetch={refetch}
        />
      )}
    </Flyout>
  );
};

export default StartEdit;
