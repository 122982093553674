import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import uniq from 'lodash/uniq';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useTracker } from '@app/hooks/useTracker';
import {
  EJobType,
  StartEditPropertiesJobData,
  StartEditPropertiesJobInput,
} from '@app/queries/organizations/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { IGraphQLStream } from '@app/queries/streams/types';
import { getEditDataObject, getStartEditJobQuery } from './utils';

interface Props {
  jobType: EJobType;
  jobID: string;
  nSelectedProperties: number;
  orgName: string;
  selectedAttributes: IGraphQLAttributeMetadata[];
  selectedProperties: IGraphQLProperty[];
  stream: IGraphQLStream;
  setHttpURL: Dispatch<SetStateAction<string>>;
  setInProgressItems: Dispatch<SetStateAction<string>>;
  setJobID: Dispatch<SetStateAction<string>>;
  successCallback: () => void;
}

interface Return {
  handleStart: () => void;
  isLoading: boolean;
}

export default ({
  jobType,
  orgName,
  selectedAttributes,
  selectedProperties,
  stream,
  setHttpURL,
  setInProgressItems,
  setJobID,
  successCallback,
}: Props): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timerRef = useRef<number>();
  const tracker = useTracker();
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;
  const jobsApolloClient = useJobsApolloClient();

  useEffect(() => () => clearTimeout(timerRef.current), []);

  // ----- START EDIT PROPERTIES JOB MUTATION ----- //

  const query = getStartEditJobQuery(jobType);

  const [getStreamQuery] = useLazyQuery(GET_STREAM, {
    variables: {
      isAdmin,
      slug: stream.slug,
      userCode: account?.userCode || '',
    },
  });

  const [createJobMutation] = useMutation<StartEditPropertiesJobData, StartEditPropertiesJobInput>(
    query,
    {
      awaitRefetchQueries: true,
      ...(jobType !== EJobType.AddLosses &&
        jobType !== EJobType.EditLosses && { client: jobsApolloClient }),
      onCompleted: async (data) => {
        const result = getEditDataObject(jobType, data);
        setJobID(result.jobID);
        setHttpURL(result?.worksheet.httpURL);
        window.location.href = result?.worksheet.httpURL;

        setIsLoading(false);
        successCallback();
        getStreamQuery();
      },
      onError: (error) => {
        setIsLoading(false);
        const { message } = error;
        // replacing part of the error message that says "propertyID 'abc'" with "property
        // 'locationName'"
        const archipelagoID = message
          .substring(message.indexOf('propertyID'), message.length)
          .split("'")[1];
        const locationName = selectedProperties.filter(
          (property) => property.archipelagoId === archipelagoID,
        )[0].locationName;
        const errorMessage = message
          .replace(archipelagoID, locationName || '')
          .replace('propertyID', 'property')
          .replace('attributeName', 'attribute');

        setInProgressItems(errorMessage);
      },
    },
  );

  const handleCreateJob = (streamSlug: string) => {
    const attributeNames = uniq(selectedAttributes.map((attribute) => attribute.name));
    const propertyIDs = selectedProperties.map((property) => property.archipelagoId as string);
    const propertyArchipelagoIDs = propertyIDs;
    let input;

    switch (jobType) {
      case EJobType.Enrichment:
        input = {
          attributeNames,
          orgName,
          propertyIDs,
          streamSlug,
        };
        break;
      case EJobType.EditLosses:
        input = {
          attributeNames: attributeNames?.filter((name) => (name as string) !== 'losses'),
          orgName,
          propertyArchipelagoIDs,
        };
        break;
    }

    createJobMutation({
      variables: {
        input: input,
      },
    });
  };

  const handleStart = () => {
    // TODO - Move Edit Losses event to api tracking
    if (jobType === EJobType.EditLosses) {
      const trackerEvent = getTrackerEvent(stream, selectedAttributes, selectedProperties);
      tracker.track(`Manage Properties - ${trackerEvent.name} - Start`, trackerEvent.payload);
    } else {
      sendMixpanelEvent(tracker, 'Edit properties with an excel file: start edit', stream);
    }

    setIsLoading(true);
    handleCreateJob(stream.slug);
  };

  return {
    handleStart,
    isLoading,
  };
};

const getTrackerEvent = (
  stream: IGraphQLStream,
  selectedAttributes: Array<IGraphQLAttributeMetadata>,
  selectedProperties: Array<IGraphQLProperty>,
) => ({
  name: 'Edit Losses',
  payload: {
    attributes: uniq(selectedAttributes.map((attribute) => attribute.name)),
    properties: selectedProperties.map((property) => property.archipelagoId as string),
    stream_id: stream.id,
    stream_name: stream.name,
    stream_slug: stream.slug,
  },
});
