import React from 'react';
import { SearchSelect } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useQueryState } from '@app/hooks/useQueryState';
import { useTracker } from '@app/hooks/useTracker';
import { useCopilotContext } from '../../context/context';

export const AttributeSelect: React.FC = () => {
  const [_, setQueryState] = useQueryState();
  const eventTracker = useTracker();
  const { selectedOrganization } = useUserSession();

  const {
    workspaceData: {
      searchFormValues: { propertyArchipelagoID, attributeName, attributesWithMappingsOnly },
    },
    attributeQueryMappings,
    loadingMappings,
    attributesQuery,
  } = useCopilotContext();

  let attributes = attributesQuery?.items || [];
  if (attributesWithMappingsOnly) {
    attributes = attributes?.filter(({ metadata: { name } }) => !!attributeQueryMappings?.[name]);
  }

  const attributeOptions = attributes?.map(({ metadata: { displayName, name } }) => ({
    label: displayName || name,
    value: name,
  }));

  return (
    <SearchSelect
      loading={attributesQuery?.loading || loadingMappings}
      options={attributeOptions || []}
      value={!!attributeName ? attributeName : null}
      clearable={false}
      placeholder="Choose an attribute"
      onChange={(newAttributeName) => {
        setQueryState({ archipelagoID: propertyArchipelagoID, attributeName: newAttributeName });
        eventTracker.track('Copilot: attribute selected', {
          archipelagoID: propertyArchipelagoID,
          attributeName: newAttributeName,
          event_surface: 'Copilot',
          orgID: selectedOrganization?.id,
          orgName: selectedOrganization?.name,
        });
      }}
    />
  );
};
