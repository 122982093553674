import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { MultiSelectOption, SearchSelectOption } from 'ui';
import { AddressObjectType } from '@app/components/GoogleAutocomplete/types';
import { AttributeLocks, CountryCurrencyCode, Property } from '@app/graphql/types';
import { TrackGroupSovManager, useTracker } from '@app/hooks/useTracker';
import { useStreamContext } from '../../StreamProvider';
import { useRequiredFieldOptions } from '../hooks/useRequiredFieldOptions';

interface IAddPropertyFlyoutContext {
  countryOptions: SearchSelectOption[];
  currencyOptions: SearchSelectOption[];
  selectedAttributes: MultiSelectOption[];
  setSelectedAttributes: (attributes: MultiSelectOption[]) => void;
  countryToCodeMap: { [key: CountryCurrencyCode['country']]: CountryCurrencyCode['countryCode'] };
  countryToCurrencyMap: {
    [key: CountryCurrencyCode['country']]: CountryCurrencyCode['currencyCode'];
  };
  isLoadingOptions: boolean;
  isClientIdDuplicate: boolean;
  setIsClientIdDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
  isClientIdConfirmed: boolean;
  setIsClientIdConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  clientIdState: string;
  setClientIdState: React.Dispatch<React.SetStateAction<string>>;
  customerProvidedGeocode: boolean;
  setCustomerProvidedGeocode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGoogleAddress: AddressObjectType;
  setSelectedGoogleAddress: React.Dispatch<React.SetStateAction<AddressObjectType>>;
  documentIds: string[];
  setDocumentIds: React.Dispatch<React.SetStateAction<string[]>>;
  subValueAttributes: string[];
  setSubValueAttributes: React.Dispatch<React.SetStateAction<string[]>>;
  property: Property;
  setProperty: React.Dispatch<React.SetStateAction<Property>>;
  propertyAttributeLocks: AttributeLocks;
  setPropertyAttributeLocks: React.Dispatch<React.SetStateAction<AttributeLocks>>;
}

const AddPropertyFlyoutContext = createContext({} as IAddPropertyFlyoutContext);

export const AddPropertyFlyoutProvider = ({ children }: { children: ReactNode }) => {
  const [selectedAttributes, setSelectedAttributes] = useState<MultiSelectOption[]>([]);
  const { stream } = useStreamContext();
  const [isClientIdDuplicate, setIsClientIdDuplicate] = useState(false);
  const [isClientIdConfirmed, setIsClientIdConfirmed] = useState(false);
  // client id used to check for previous value when switching between tabs
  const [clientIdState, setClientIdState] = useState<string>();
  const [documentIds, setDocumentIds] = useState([]);
  const [customerProvidedGeocode, setCustomerProvidedGeocode] = useState(false);
  const [selectedGoogleAddress, setSelectedGoogleAddress] = useState<AddressObjectType>();
  const [subValueAttributes, setSubValueAttributes] = useState<string[]>([]);
  const [property, setProperty] = useState<Property>();
  const [propertyAttributeLocks, setPropertyAttributeLocks] = useState<AttributeLocks>();
  const {
    countryOptions,
    currencyOptions,
    isLoadingOptions,
    countryToCodeMap,
    countryToCurrencyMap,
  } = useRequiredFieldOptions();

  const tracker = useTracker();
  const { propertyAttributeMetadata } = useStreamContext();

  useEffect(() => {
    if (propertyAttributeMetadata) {
      const selected = propertyAttributeMetadata
        .filter((attribute) => attribute.isCustom && !attribute.derived)
        .map((attribute) => ({ label: attribute.displayName, value: attribute.name }));
      setSelectedAttributes(selected);
    }
  }, [propertyAttributeMetadata]);

  useEffect(() => {
    if (!isEmpty(selectedGoogleAddress)) {
      const { streetAddress, city, county, state, country, postalCode, latitude, longitude } =
        selectedGoogleAddress;
      tracker.track(`${TrackGroupSovManager.prefix}: Suggested address selected`, {
        city,
        country,
        county,
        event_surface: TrackGroupSovManager.event_surface,
        latitude,
        longitude,
        organization_name: stream?.orgName,
        postal_code: postalCode,
        state,
        stream_name: stream?.name,
        stream_slug: stream?.slug,
        street_address: streetAddress,
      });
    }
  }, [JSON.stringify(selectedGoogleAddress)]);

  return (
    <AddPropertyFlyoutContext.Provider
      value={{
        countryOptions,
        countryToCodeMap,
        clientIdState,
        countryToCurrencyMap,
        currencyOptions,
        customerProvidedGeocode,
        isClientIdConfirmed,
        isClientIdDuplicate,
        documentIds,
        isLoadingOptions,
        selectedAttributes,
        selectedGoogleAddress,
        setClientIdState,
        setSelectedAttributes,
        property,
        propertyAttributeLocks,
        setCustomerProvidedGeocode,
        setDocumentIds,
        setIsClientIdDuplicate,
        setIsClientIdConfirmed,
        setProperty,
        setPropertyAttributeLocks,
        setSelectedGoogleAddress,
        setSubValueAttributes,
        subValueAttributes,
      }}
    >
      {children}
    </AddPropertyFlyoutContext.Provider>
  );
};

export const useAddPropertyFlyoutContext = () => useContext(AddPropertyFlyoutContext);
