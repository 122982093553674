import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import Markdown from '@app/components/Markdown/Markdown';
import { useTracker } from '@app/hooks/useTracker';
import Author from './TourPanelAuthor';
import Title from './TourPanelTitle';
import { IAuthor } from './types';

const Container = styled.div`
  label: TourPanelContainer;
  font-size: 14px;

  a {
    color: #2d9cdb;
    text-decoration: none;
  }

  span,
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 150%;
  }
`;

interface IProps {
  content: string;
  author?: IAuthor;
  title?: string;
  step?: number;
}

const TourPanel: FC<IProps> = ({ content, author, title, step }) => {
  const tracker = useTracker();
  const { stream } = useStreamContext() || {};
  useEffect(() => {
    if (step !== undefined) {
      tracker.track('View Highlight', {
        highlight_number: step + 1,
        highlight_title: title,
        stream_id: stream?.id,
        stream_name: stream?.name,
        stream_slug: stream?.slug,
      });
    }
  }, [step]);
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Markdown content={content} />
      {author && <Author author={author} />}
    </Container>
  );
};

export default TourPanel;
