import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from 'ui';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useTracker } from '@app/hooks/useTracker';
import { EJobStatus, IUpdateJobData, IUpdateJobVariables } from '@app/queries/organizations/types';
import { UPDATE_JOB } from '@app/queries/organizations/updateJob';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { IGraphQLStream } from '@app/queries/streams/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { getErrorMessage } from '@app/utils/getErrorMessage';

interface Props {
  handleCancel: () => Promise<void>;
  isLoading: boolean;
}

export default (
  callback: () => void,
  jobID: string,
  streamSlug: string,
  stream?: IGraphQLStream,
): Props => {
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;
  const jobsApolloClient = useJobsApolloClient();
  const tracker = useTracker();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const [getStream] = useLazyQuery(GET_STREAM, {
    variables: {
      isAdmin,
      slug: streamSlug,
      userCode: account?.userCode || '',
    },
  });

  const [updateJob] = useMutation<IUpdateJobData, IUpdateJobVariables>(UPDATE_JOB, {
    awaitRefetchQueries: true,
    client: jobsApolloClient,
    onCompleted: () => getStream(),
    onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
  });

  const handleCancel = async () => {
    sendMixpanelEvent(tracker, 'Edit Data Flyout: Confirm cancel', stream);
    setIsLoading(true);
    await updateJob({
      variables: {
        input: {
          jobID,
          status: EJobStatus.Archived,
        },
      },
    });
    setIsLoading(false);
    callback();
  };

  return {
    handleCancel,
    isLoading,
  };
};
