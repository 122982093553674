import React, { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  Tabs,
  useToast,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useSubmitTasksMutation } from '@app/graphql/precheck/mutations/submitTasks/__generated__/submitTasks.generated';
import { PropertyDataInput } from '@app/graphql/types';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useTaskContext } from '../V2/context/TaskContext';
import { usePrecheckContext } from '@app/precheck/context/PrecheckContext';
import { FlyoutHeader } from '../V2/FlyoutHeader';
import { ValuationOutlier } from '../ValuationOutlier/ValuationOutlier';
import { MissingAttributes } from '../MissingAttributes/MissingAttributes';
import { useTracker } from '@app/hooks/useTracker';
import { RecommendationType } from '@app/graphql/precheck/precheck.types';
interface Props {
  taskID: string;
  clearSelectedTask: () => void;
  dismissHandler: () => void;
}

const StyledFlyout = styled(EuiFlyout)`
  inline-size: 45vw !important;
`;

enum TAB_IDS {
  MISSING_ATTRIBUTES_TAB = 'missing-attributes-tab',
  VALUATION_OUTLIERS_TAB_ID = 'valuation-outliers-tab',
}

export const TaskFlyout: FC<Props> = ({ dismissHandler, taskID, clearSelectedTask }) => {
  const { tasks, loading, refetch } = useTaskContext();
  const client = usePrecheckClient();
  const tracker = useTracker();

  const { closeFlyout } = useContext(FlyoutContext);
  const toast = useToast();
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const { stream } = usePrecheckContext();
  const [_, setSelectedTab] = useState<string>(TAB_IDS.MISSING_ATTRIBUTES_TAB);
  const task = tasks.find((t) => t.id === taskID);

  const containsValuationOutlier = task.attributes.some((attribute) => {
    return attribute.recommendationType === RecommendationType.ValuationOutlier;
  });

  const defaultValues = task.attributes
    .filter((attribute) => {
      return attribute.recommendationType === RecommendationType.ValuationOutlier;
    })
    .reduce((acc, attribute) => {
      const value = task.property?.[attribute.name];
      acc[attribute.attributeMetadata.name] = value;
      return acc;
    }, {});

  const formMethods = useForm({
    mode: 'onSubmit',
    // if this doesn't work, need to do a useEffect, form reset
    defaultValues,
  });

  const [submitTask, { loading: submitLoading }] = useSubmitTasksMutation({
    client,
    onCompleted: async () => {
      clearSelectedTask();
      await refetch();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const completeTask = (propertyData?: PropertyDataInput) => {
    tracker.track('Pre-Check: Task Completed', {
      taskId: task.id,
    });
    submitTask({
      variables: {
        input: {
          orgName: selectedOrganization.name,
          tasksInput: [
            {
              propertyArchipelagoID: task.propertyId,
              propertyData: propertyData,
              taskID: task.id,
            },
          ],
        },
      },
    });
  };

  const submitHandler = () => {
    const propertyInput = {};
    const attributesNames = [];
    const vals = formMethods.getValues();
    for (const val in vals) {
      if (vals[val] !== '' && vals[val] !== undefined) {
        propertyInput[val] = vals[val];
        attributesNames.push(val);
      }
    }
    completeTask(propertyInput);
  };

  if (!task) return null;

  const disabled =
    task.assignees.length === 0 ||
    !task.assignees.some((assignee) => assignee.email === account.email);

  const tabs = [
    {
      content: <MissingAttributes disabled={disabled} task={task} />,
      id: TAB_IDS.MISSING_ATTRIBUTES_TAB,
      label: 'Missing Attributes',
      onClick: () => {
        setSelectedTab(TAB_IDS.MISSING_ATTRIBUTES_TAB);
      },
    },
  ];

  if (containsValuationOutlier) {
    tabs.push({
      content: (
        <ValuationOutlier
          disabled={disabled}
          defaultValues={defaultValues}
          formValues={formMethods.getValues()}
          task={task}
        />
      ),
      id: TAB_IDS.VALUATION_OUTLIERS_TAB_ID,
      label: 'Valuation Outlier',
      onClick: () => {
        setSelectedTab(TAB_IDS.VALUATION_OUTLIERS_TAB_ID);
      },
    });
  }

  return (
    <FormProvider {...formMethods}>
      <StyledFlyout
        onClose={() => {
          clearSelectedTask();
          closeFlyout();
        }}
        onSubmit={formMethods.handleSubmit(submitHandler)}
      >
        <FlyoutHeader task={task} streamSlug={stream?.slug} />

        <EuiFlyoutBody>
          {loading ? (
            <LoadingSpinnerV2 />
          ) : (
            <Tabs initialTabId={TAB_IDS.MISSING_ATTRIBUTES_TAB} tabs={tabs} />
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <ButtonEmpty
                loading={submitLoading}
                disabled={submitLoading}
                onClick={dismissHandler}
                color="danger"
                iconName="trash"
                label="Dismiss"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button
                loading={submitLoading}
                disabled={disabled || submitLoading}
                fill
                onClick={submitHandler}
                type="submit"
                label="Save"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </StyledFlyout>
    </FormProvider>
  );
};
