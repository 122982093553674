import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from 'ui';

import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { colorMap } from './constants/colorMap';
import { labelMap } from './constants/labelMap';
import { StyledBadge } from './TaskTable.emotion';
import { useTaskContext } from './context/TaskContext';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import {
  StyledBadgeContainer,
  StyledDescription,
  StyledExamples,
  StyledLoadContainer,
  StyledPanel,
  StyledPercent,
} from './DataCompleteness.emotion';
import { AttritionalReason, CATReasons, COPEReason } from './context/types';
import { useTracker } from '@app/hooks/useTracker';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { FEATURE_TYPE_PRECHECK_VALUATIONS } from '@app/platform/SystemSettings/Flags/types';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';

const formatPercentage = (tiv, totalTiv) => {
  const percent = (tiv / totalTiv) * 100;

  if (percent < 1 && percent > 0) {
    return percent.toFixed(2);
  } else {
    return Math.round(percent);
  }
};

export const DataCompleteness = () => {
  const { filters, totalTiv, taskTotals, loading, error } = useTaskContext();
  const tracker = useTracker();
  const { selectedOrganization } = useUserSession();

  const recordClick = (cardType) => {
    tracker.track(`Pre-Check: ${cardType} Completeness Card Cliicked`);
  };

  const valuationOutliersEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_PRECHECK_VALUATIONS,
  );

  if (loading) {
    return (
      <StyledLoadContainer>
        <LoadingSpinnerV2 />
      </StyledLoadContainer>
    );
  }

  if (error) {
    console.error(error);
    return <div>Oops... Something went wrong, please try again</div>;
  }

  const copeActive = filters.reasons.includes(COPEReason.cope);
  const cats = Object.values(CATReasons);

  const catActive = cats.every((reason) => filters.reasons.includes(reason));
  const attrActive = filters.reasons.includes(AttritionalReason.attritional);
  const outlierActive = [
    ValuationOutlierReason.Overvalued,
    ValuationOutlierReason.Undervalued,
  ].every((reason) => filters.reasons.includes(reason));

  const cards = [
    {
      type: 'cope',
      isActive: copeActive,
      description: `COPE data provides the fundamental property information needed to assess catastrophe
            and all other peril risks.`,
      examples: `Examples: construction type, year built, fire protection`,
      badges: (
        <StyledBadge withMargin color={colorMap[COPEReason.cope]}>
          {labelMap[COPEReason.cope]}
        </StyledBadge>
      ),
      percentText: `is missing relevant COPE data`,
    },
    {
      type: 'cat',
      isActive: catActive,
      description: `Catastrophe (CAT) secondary modifiers influence property vulnerability to perils
                such as earthquakes, floods, or hurricanes, affecting average annual loss models.`,
      examples: 'Examples: first floor elevation, roofing material',
      badges: filters.reasonOptions.map((reason) =>
        cats.includes(reason.value as any) ? (
          <StyledBadge withMargin color={colorMap[reason.value]}>
            {labelMap[reason.value]}
          </StyledBadge>
        ) : null,
      ),
      percentText: `is missing relevant CAT modifier data`,
    },
    valuationOutliersEnabled
      ? {
          type: 'valuation',
          isActive: outlierActive,
          description: `Outliers are based on a properties current cost per floor area, compared to the average of similar properties.`,
          examples: 'Outliers are >1 standard deviation away from our benchmarks',
          badges: (
            <>
              <StyledBadge withMargin color={colorMap[ValuationOutlierReason.Overvalued]}>
                Overvalued
              </StyledBadge>
              <StyledBadge withMargin color={colorMap[ValuationOutlierReason.Undervalued]}>
                Undervalued
              </StyledBadge>
            </>
          ),
          percentText: `has a potentiual valuation outlier`,
        }
      : {
          type: 'attritional',
          isActive: attrActive,
          description: `Attritional attributes are a subset of COPE data that impact higher frequency, lower
                severity losses.`,
          examples: 'Examples: building condition, year built',
          badges: (
            <StyledBadge withMargin color={colorMap[AttritionalReason.attritional]}>
              {labelMap[AttritionalReason.attritional]}
            </StyledBadge>
          ),
          percentText: `is missing relevant attritional data`,
        },
  ];

  const applyFilter = (card) => {
    let activeValues = [];
    let inActiveValues = [];

    if (card.type === 'cope') {
      activeValues = filters.reasons.filter((r) => r !== COPEReason.cope);
      inActiveValues = [...filters.reasons, COPEReason.cope];
    }
    if (card.type === 'cat') {
      activeValues = filters.reasons.filter((r: CATReasons) => !cats.includes(r));
      inActiveValues = [...filters.reasons, ...cats];
    }
    if (card.type === 'attritional') {
      activeValues = filters.reasons.filter((r) => r !== AttritionalReason.attritional);
      inActiveValues = [...filters.reasons, AttritionalReason.attritional];
    }

    if (card.type === 'valuation') {
      activeValues = filters.reasons.filter(
        (r) => r !== ValuationOutlierReason.Overvalued && r !== ValuationOutlierReason.Undervalued,
      );
      inActiveValues = [
        ...filters.reasons,
        ValuationOutlierReason.Overvalued,
        ValuationOutlierReason.Undervalued,
      ];
    }

    if (card.isActive) {
      filters.setFilter('reasons', activeValues);
    } else {
      filters.setFilter('reasons', inActiveValues);
    }
  };

  return (
    <EuiFlexGroup wrap>
      {cards.map((card) => (
        <EuiFlexItem style={{ minWidth: '400px' }}>
          <a
            style={{ color: 'unset' }}
            href="javascript:void(0)"
            onClick={(e) => {
              e.preventDefault();
              recordClick(card.type);
              applyFilter(card);
            }}
          >
            <StyledPanel active={card.isActive} hasShadow={false}>
              <StyledBadgeContainer>{card.badges}</StyledBadgeContainer>
              <EuiSpacer size="m" />
              <div style={{ minHeight: '50px' }}>
                <EuiTitle size="xxs">
                  <h3>
                    <StyledPercent>
                      {formatPercentage(taskTotals[card.type].tiv, totalTiv)}%
                    </StyledPercent>
                    ({formatStreamCurrency(taskTotals[card.type].tiv)}) of total TIV{' '}
                    {card.percentText}
                  </h3>
                </EuiTitle>
              </div>

              <EuiSpacer size="xs" />

              <StyledDescription>
                <EuiText size="s">{card.description}</EuiText>
              </StyledDescription>

              <EuiSpacer size="xs" />

              <StyledExamples>{card.examples}</StyledExamples>
            </StyledPanel>
          </a>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};
