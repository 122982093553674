export const labelMap = {
  'Earthquake risk': 'Earthquake',
  'Missing COPE data': 'COPE',
  'Wind risk': 'Wind',
  'Flood risk': 'Flood',
  'SCS risk': 'Storm',
  'Attritional risk': 'Attritional',
  'Valuation Outlier': 'Valuation outlier',
  Undervalued: 'Undervalued',
  Overvalued: 'Overvalued',
};
