import React from 'react';
import { Checkbox } from 'ui';
import { useSelection } from '@app/contexts/SelectionContext/SelectionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import { getAdjustedRowIndex, sendMixpanelEvent } from '../PropertiesDataGrid/utils';

export const SelectionRowCell = ({ rowIndex, data, pagination }) => {
  const adjustedRowIndex = getAdjustedRowIndex(rowIndex, pagination);
  const { selectedRows, updateSelectedRows } = useSelection();
  const isChecked = Boolean(selectedRows[data?.[adjustedRowIndex]?.id]);
  const { stream } = useStreamContext();
  const tracker = useTracker();

  return (
    <div>
      <Checkbox
        id={`${adjustedRowIndex}`}
        aria-label={`Select row ${adjustedRowIndex}, ${data?.[adjustedRowIndex]?.name}`}
        checked={isChecked}
        onChange={(e) => {
          sendMixpanelEvent(
            tracker,
            e.target.checked ? 'Select property' : 'Unselect property',
            stream,
            { property_id: data?.[adjustedRowIndex]?.archipelagoId },
          );
          if (e.target.checked) {
            updateSelectedRows({
              action: 'add',
              id: data?.[adjustedRowIndex]?.id,
              property: data?.[adjustedRowIndex],
              rowIndex,
            });
          } else {
            updateSelectedRows({ action: 'delete', id: data?.[adjustedRowIndex]?.id, rowIndex });
          }
        }}
      />
    </div>
  );
};
