import React from 'react';
import StarRatings from 'react-star-ratings';
import loadable from '@loadable/component';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  EuiToolTip,
  Icon,
  useEuiTheme,
} from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckContext } from '../context/PrecheckContext';

const AlertTriangle = loadable(() =>
  import('react-feather').then((module) => ({ default: module.AlertTriangle })),
);

export const ScoreQuality = () => {
  const { recommendations, precheckUser, stream } = usePrecheckContext();
  const { selectedOrganization } = useUserSession();

  const { euiTheme } = useEuiTheme();

  const tracker = useTracker();

  return (
    <div
      style={{
        marginBottom: '12px',
        textAlign: 'center',
      }}
    >
      <EuiFlexGroup gutterSize="s" justifyContent="center">
        <EuiFlexItem grow={false}>
          <EuiTitle size="xs">
            <h3>Data Quality Score</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiToolTip content="The data quality score is a 5 star rating representing the overall completeness and accuracy of the data in the SOV relative to its risk profile.">
            <Icon color="primary" name="help" size="m" />
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <StarRatings
        rating={recommendations?.data?.grade || 0}
        starDimension="32px"
        starRatedColor={euiTheme.colors.primary}
        numberOfStars={5}
        name="rating"
      />
      <EuiSpacer size="m" />
      {recommendations.data.buckets.length > 0 && (
        <EuiFlexGroup
          justifyContent="center"
          style={{ marginTop: '12px', textAlign: 'left' }}
          gutterSize="m"
        >
          <EuiFlexItem grow={false}>
            <AlertTriangle color="#83650a" />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ alignSelf: 'center' }}>
            <EuiText size="s">
              <EuiTextColor color="subdued">
                Improve your score with our{' '}
                <a
                  href="#recommendations"
                  onClick={() => {
                    tracker.track('Pre-Check: Recommendation Link clicked', {
                      event_surface: 'PreCheck',
                      organization_id: selectedOrganization.id,
                      precheck_user_type: precheckUser.type,
                      stream_id: stream.id,
                      stream_slug: stream.slug,
                    });
                  }}
                  style={{ color: euiTheme.colors.primary }}
                >
                  {recommendations.data.buckets.reduce(
                    (accumulator, currentValue) => accumulator + currentValue.properties.length,
                    0,
                  )}{' '}
                  recommendations
                </a>
              </EuiTextColor>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </div>
  );
};
