import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment, { Moment } from 'moment';
import {
  CurrencyField,
  EuiDatePicker,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiTextColor,
  EuiToolTip,
  FloatField,
  IntegerField,
  SearchSelect,
  TextField,
  YearField,
} from 'ui';
import { GoogleAutocompleteInput } from '@app/components/GoogleAutocomplete/GoogleAutocompleteInput';
import PropertiesGridTooltip from '@app/components/PropertiesGrid/PropertiesGridTooltip/PropertiesGridTooltip';
import { ProvenanceType } from '@app/components/PropertiesGrid/PropertiesGridTooltip/types';
import { AttributeEditInfo } from '@app/graphql/types';
import { useTracker } from '@app/hooks/useTracker';
import { IAttributeProvenance } from '@app/queries/properties/types';
import { AttributeRowLabelContainer } from './AddPropertyFlyout.emotion';
import { boolToString, stringToBool } from './utils';

export enum AttributeRowTypeEnum {
  address = 'address',
  currency = 'currency',
  number = 'number',
  search = 'search',
  select = 'select',
  text = 'text',
  integer = 'integer',
  year = 'year',
  date = 'date',
  boolean = 'boolean',
}

export enum AttributeRowLayoutEnum {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface IAttributeRowProps {
  id: string;
  label?: string;
  type: AttributeRowTypeEnum;
  required?: boolean;
  inputProps?: any;
  tooltipContent?: React.ReactNode;
  provenance?: {
    provenance: IAttributeProvenance;
    editInfo: Array<AttributeEditInfo>;
  };
  info?: string;
  layout?: AttributeRowLayoutEnum;
  labelAction?: JSX.Element;
}

export const AttributeRow = ({
  id,
  label,
  type,
  required = false,
  inputProps = {},
  tooltipContent,
  provenance,
  info,
  layout = AttributeRowLayoutEnum.horizontal,
  labelAction,
}: IAttributeRowProps) => {
  const titleId = `${id}-title`;
  const [hover, setHover] = useState<boolean>(layout === 'vertical');

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const tracker = useTracker();

  const formInputProps = register(id);
  const inputTypeMap = {
    [AttributeRowTypeEnum.address]: GoogleAutocompleteInput,
    [AttributeRowTypeEnum.currency]: CurrencyField,
    [AttributeRowTypeEnum.number]: FloatField,
    [AttributeRowTypeEnum.search]: EuiFieldSearch,
    [AttributeRowTypeEnum.select]: SearchSelect,
    [AttributeRowTypeEnum.boolean]: SearchSelect,
    [AttributeRowTypeEnum.text]: TextField,
    [AttributeRowTypeEnum.integer]: IntegerField,
    [AttributeRowTypeEnum.year]: YearField,
    [AttributeRowTypeEnum.date]: EuiDatePicker,
  };

  const InputComponent = inputTypeMap[type];

  const inputError = errors?.[id];
  let input;
  // If inputProps.onChange is empty or not set, add a default onChange handler
  if (!inputProps.onChange) {
    delete inputProps.onChange;
  }
  const customChangeHandler = inputProps.onChange;

  if (type === AttributeRowTypeEnum.text) {
    input = (
      <InputComponent
        aria-labelledby={titleId}
        {...formInputProps}
        {...inputProps}
        invalid={!!inputError} // TODO: refactor TextField to switch prop to isInvalid to match the underlying EuiFieldText
      />
    );
  } else if (type === AttributeRowTypeEnum.date) {
    delete inputProps.onChange;
    input = (
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <InputComponent
            aria-labelledby={titleId}
            inputRef={field.ref}
            name={field.name}
            selected={field.value ? moment(field.value).utc() : undefined}
            onChange={(date: Moment) => {
              customChangeHandler?.(date ? date.utc().format('YYYY-MM-DD') : null);
              field.onChange(date ? date.utc().format('YYYY-MM-DD') : null);
            }}
            onBlur={field.onBlur}
            {...inputProps}
          />
        )}
      />
    );
  } else if (type === AttributeRowTypeEnum.select || type === AttributeRowTypeEnum.address) {
    delete inputProps.onChange;
    input = (
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <InputComponent
            aria-labelledby={titleId}
            inputRef={field.ref}
            name={field.name}
            value={field.value}
            onChange={(value) => {
              customChangeHandler?.(value);
              field.onChange(value);
            }}
            onBlur={field.onBlur}
            {...inputProps}
            // TODO: refactor SearchSelect to switch prop to isInvalid to match the underlying EuiFieldText
            invalid={type === AttributeRowTypeEnum.select ? !!inputError : undefined}
            isInvalid={type === AttributeRowTypeEnum.address ? !!inputError : undefined}
          />
        )}
      />
    );
  } else if (
    type === AttributeRowTypeEnum.number ||
    type === AttributeRowTypeEnum.currency ||
    type === AttributeRowTypeEnum.integer ||
    type === AttributeRowTypeEnum.year
  ) {
    input = (
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <InputComponent
            aria-labelledby={titleId}
            {...field}
            {...inputProps}
            isInvalid={!!inputError}
          />
        )}
      />
    );
  } else if (type === AttributeRowTypeEnum.boolean) {
    input = (
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <InputComponent
            aria-labelledby={titleId}
            inputRef={field.ref}
            name={field.name}
            value={boolToString(field.value)}
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' },
            ]}
            onChange={(value) => {
              customChangeHandler?.(value);
              field.onChange(stringToBool(value));
            }}
            clearable
            onBlur={field.onBlur}
            {...inputProps}
            invalid={!!inputError}
            isInvalid={!!inputError}
          />
        )}
      />
    );
  } else {
    delete inputProps.onChange;

    input = (
      <InputComponent
        aria-labelledby={titleId}
        name={formInputProps.name}
        onChange={(value) => {
          customChangeHandler?.(value);
          formInputProps.onChange(value);
        }}
        onBlur={formInputProps.onBlur}
        inputRef={formInputProps.ref}
        isInvalid={!!inputError}
        {...inputProps}
      />
    );
  }

  if (tooltipContent) {
    input = (
      <EuiToolTip content={tooltipContent} display="block" position="top">
        {input}
      </EuiToolTip>
    );
  }

  const hasProvenanceTooltip = !!(provenance?.provenance || provenance?.editInfo?.length);

  let labelComponent = (
    <EuiText
      id={titleId}
      style={{ textDecoration: hasProvenanceTooltip ? 'underline' : undefined }}
    >
      <EuiFlexGroup>
        <EuiFlexItem grow={layout === 'vertical' ? false : 1}>
          <h5>
            {label}
            {required ? <EuiTextColor color="danger">*</EuiTextColor> : ''}
          </h5>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ visibility: hover ? 'visible' : 'hidden' }}>
          {labelAction}
        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>
    </EuiText>
  );

  if (hasProvenanceTooltip) {
    labelComponent = (
      <PropertiesGridTooltip
        dataTestId="inline-edit-properties-data-grid-tooltip"
        handleDocumentDownload={(doc) => {
          tracker.track('Download Document', {
            download_type: ProvenanceType.Attribute,
            event_surface: 'property_modal',
            filename: doc.filename,
            url: doc.httpURL,
            // ...streamContext,
          });
        }}
        provenance={provenance.provenance}
        editInfo={provenance.editInfo}
        handleOpen={() => {
          tracker.track('Open Provenance', {
            // ...streamContext,
            attribute: id,
          });
        }}
      >
        {labelComponent}
      </PropertiesGridTooltip>
    );
  }

  labelComponent = (
    <AttributeRowLabelContainer grow={layout === 'vertical' ? false : 1}>
      {labelComponent}
    </AttributeRowLabelContainer>
  );
  if (layout === 'vertical') {
    return (
      <>
        <EuiFlexGroup gutterSize="none" direction="column">
          {label && labelComponent}
          <EuiFormRow fullWidth isInvalid={!!inputError} error={inputError?.message}>
            {input}
          </EuiFormRow>
          {info && (
            <EuiText size="s" color="subdued">
              {info}
            </EuiText>
          )}
        </EuiFlexGroup>
      </>
    );
  }
  return (
    <EuiFlexGroup gutterSize="s">
      {label && labelComponent}
      <EuiFlexItem grow={2}>
        <EuiFormRow fullWidth isInvalid={!!inputError} error={inputError?.message}>
          {input}
        </EuiFormRow>
        {info && (
          <EuiText size="s" color="subdued">
            {info}
          </EuiText>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
