import React, { useEffect, useState } from 'react';
import { usePageState } from '@onarchipelago/cx/Stream/utils';
import { Select } from 'ui';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { TrackStreamPage, useTracker } from '@app/hooks/useTracker';
import { IGroupedSnapshots } from './types';
import useGetSnapshotButtonHandleChange from './useGetSnapshotButtonHandleChange';
import useGetSnapshotOptions from './useGetSnapshotOptions';
import { getSnapshot } from './utils';

interface Props {
  isAdmin?: boolean;
  defaultSnapshot?: string;
  snapshotType: 'changesSince' | 'current';
  snapshots: Array<IGroupedSnapshots>;
}

const SnapshotButton: React.FC<Props> = ({ defaultSnapshot, isAdmin, snapshotType, snapshots }) => {
  const [pageState, setPageState] = usePageState();
  const tracker = useTracker();

  const { stream, marketVisibleToggle } = useStreamContext();

  useEffect(() => {
    // This clears out the snapshot select value when some other control clears its pageState value.
    if (pageState?.changesSince?.length < 1) {
      if (snapshotType === 'changesSince') {
        setValue(undefined);
      }
    }
  }, [pageState.changesSince]);

  const changesSinceSnapshot = getSnapshot(snapshots, pageState.changesSince);
  const currentSnapshot =
    getSnapshot(snapshots, pageState.currentSnapshot) || getSnapshot(snapshots, defaultSnapshot);

  let initialValue;
  if (snapshotType === 'changesSince') {
    initialValue = defaultSnapshot || changesSinceSnapshot?.id;
  } else {
    initialValue = defaultSnapshot || currentSnapshot?.id || 'latest-snapshot';
  }

  const [value, setValue] = useState<string | undefined>(initialValue);
  const { flatSnapshots, snapshotOptions } = useGetSnapshotOptions({
    changesSinceSnapshot,
    currentSnapshot,
    defaultSnapshot,
    isAdmin,
    marketVisibleToggle,
    snapshotType,
    snapshots,
  });
  const { changesSinceHandleChange, currentHandleChange } = useGetSnapshotButtonHandleChange({
    changesSinceSnapshot,
    currentSnapshot,
    snapshots: flatSnapshots,
  });

  const onChange = (snapshotId: string) => {
    const snapshot = snapshotOptions.find((s) => s.value === snapshotId);
    if (snapshotType === 'changesSince') {
      tracker.track(`${TrackStreamPage.prefix}: Change Analysis Applied`, {
        distiller_field: 'changes_since',
        distiller_value: snapshot?.label,
        event_surface: TrackStreamPage.event_surface,
        organization_name: stream.orgName,
        stream_name: stream.name,
        stream_slug: stream.slug,
      });
    } else {
      // current
      tracker.track(`${TrackStreamPage.prefix}: Changed Snapshot`, {
        event_surface: TrackStreamPage.event_surface,
        organization_name: stream.orgName,
        snapshot: snapshot?.label,
        stream_name: stream.name,
        stream_slug: stream.slug,
      });
    }

    if (snapshotType === 'changesSince') {
      changesSinceHandleChange(snapshotId);
    } else {
      currentHandleChange(snapshotId);
    }

    setValue(snapshotId);
  };

  const onClear = () => {
    setPageState({ changesSince: null });
    setValue(undefined);
  };

  return (
    <Select
      data-testid={
        snapshotType === 'changesSince'
          ? 'change-analysis-distiller-button'
          : 'view_data_as_of-distiller-button'
      }
      onClear={snapshotType === 'changesSince' && !!value ? onClear : undefined}
      options={snapshotOptions}
      value={value}
      onChange={(value) => onChange(value)}
      compressed
    />
  );
};

export default SnapshotButton;
