import React, { FC, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'ui';
import { useTracker } from '@app/hooks/useTracker';
import { IGraphQLDocument } from '@app/queries/documents/types';
import { documentZipExportHandler } from '@app/utils/export';
import { IDocumentModalStream } from './types';

interface Props {
  document?: IGraphQLDocument;
  stream: IDocumentModalStream;
}

const DownloadAllDocs: FC<Props> = ({ stream }) => {
  const tracker = useTracker();
  const apolloClient = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    tracker.track('Download Document', {
      download_type: 'stream_all',
      event_surface: 'document_modal',
      export_format: 'all',
      stream_id: stream.id,
      stream_name: stream.name,
      stream_slug: stream.slug,
    });
    documentZipExportHandler(stream.slug, stream.id, stream.defaultSnapshot, apolloClient).then(
      (downloadUrl) => {
        setIsLoading(false);
        window.location.href = downloadUrl;
      },
    );
    return true;
  };

  return (
    <Button
      data-testid="documents-modal-download-all-docs"
      onClick={handleClick}
      loading={isLoading}
      label="Download all docs (zip)"
    />
  );
};

export default DownloadAllDocs;
