import { useMemo } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { PRECHECK_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import getLink, { TypePatchers } from '../../components/Apollo/getLink';
import possibleTypes from '../../components/Apollo/possibleTypes.json';
import { IS_DEV_ENV } from '@app/config';

const preCheckUrl = PRECHECK_URL;

const cache = new InMemoryCache({
  possibleTypes: possibleTypes as any,
  typePolicies: {
    OptionalFeature: { keyFields: ['name'] },
    Query: {
      fields: {
        // add the type policy on the field that you're trying to paginate
        propertiesPage: relayStylePagination(['groupID', 'cursor']),
      },
    },
  },
});

const typePatchers: TypePatchers = {};

export const usePrecheckClient = (): ApolloClient<object> => {
  const link = getLink(typePatchers, preCheckUrl, useAuth()?.checkSession);

  const client = useMemo(
    () =>
      new ApolloClient({
        cache,
        connectToDevTools: IS_DEV_ENV,
        defaultOptions: {
          query: {
            fetchPolicy: 'network-only',
          },
          watchQuery: {
            fetchPolicy: 'network-only',
          },
        },
        link,
        queryDeduplication: true,
      }),
    [],
  );

  return client;
};
