import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IJob, JobErrors } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  CallOut,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiListGroup,
  EuiText,
  EuiTitle,
  Spacer,
  useToast,
} from 'ui';
import { AnimatedPanel } from '@app/components/Flyout/AnimatedPanel';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { propertiesModalPathname, useDecodedParams } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useTracker } from '@app/hooks/useTracker';
import { EJobStatus, EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLProperty } from '@app/queries/properties/types';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { GetStreamData, GetStreamVariables, IGraphQLStream } from '@app/queries/streams/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import DuplicatePropertyIDsCallout from '../EditDataFlyout/Callouts/DuplicatePropertyIDsCallout';
import JobErrorsCallout from '../EditDataFlyout/Callouts/JobErrorsCallout';
import { AddJobType } from './types';
import useGetProperties from './useGetProperties';
import useStartJob from './useStartJob';
import useSubmitJob from './useSubmitJob';

interface PanelConfig {
  title: string;
  submitLabel: string;
  showAffectedProperties: boolean;
  successTitle: string;
  successDescription: string;
}

const lossPanel: PanelConfig = {
  showAffectedProperties: false,
  submitLabel: 'Add losses',
  successDescription:
    'Refreshing your stream now. Loss data will be visible on the properties you selected soon.',
  successTitle: 'Added your losses',
  title: 'Add more loss data',
};
const propertyPanel: PanelConfig = {
  showAffectedProperties: true,
  submitLabel: 'Add properties',
  successDescription:
    'Refreshing your stream now. Property data will be displayed in the flyer soon.',
  successTitle: 'Added your properties',
  title: 'Add properties',
};

interface Props {
  orgName: string;
  inProgressJob?: Lock;
  stream: IGraphQLStream;
  jobType: AddJobType;
}

type TimeoutType = 'checkout' | 'submit' | 'complete';
const AddJobPanel: React.FC<Props> = ({ orgName, inProgressJob, stream, jobType }) => {
  const toast = useToast();
  const params = useDecodedParams();

  const [jobErrors, setJobErrors] = useState<JobErrors | null>(null);
  const [timeoutType, setTimeoutType] = useState<TimeoutType | null>(null);
  const [properties, setProperties] = useState<Array<IGraphQLProperty>>([]);

  const [job, setJob] = useState<IJob | null>(null);
  const [worksheet, setWorksheet] = useState<File | null>(null);

  const { closeFlyout } = useContext(FlyoutContext);
  const { account } = useAuth();
  // If there's no default job, or it wasn't started yet, download the worksheet when opening the flyout
  const shouldDownloadWorksheet = !inProgressJob || inProgressJob.canStart;

  const { title, submitLabel, showAffectedProperties, successTitle, successDescription } =
    jobType === EJobType.AddLosses ? lossPanel : propertyPanel;

  const tracker = useTracker();

  const { loading: loadingStart, retry: retryCheckout } = useStartJob({
    defaultJob: inProgressJob,
    jobType,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    onSuccess: (j) => {
      setJob(j);

      // Newly created job, download worksheet
      if (shouldDownloadWorksheet) {
        const currentTransition = j.lifeCycle[0];
        window.location.href = currentTransition.worksheet.httpURL;
      }
    },
    onTimeout: () => setTimeoutType('checkout'),
    orgName,

    stream,
  });

  const [getStreamQuery] = useLazyQuery<GetStreamData, GetStreamVariables>(GET_STREAM, {
    onCompleted: () => {
      toast({
        'data-testid': 'add-loss-job-success',
        label: successDescription,
        title: successTitle,
        type: 'success',
      });

      if (showAffectedProperties) {
        startGetProperties(job?.propertyIDs);
      } else {
        closeFlyout();
      }
    },
  });

  const { start: startGetProperties } = useGetProperties({
    onCompleted: setProperties,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    orgName,
  });

  const {
    submit,
    loading: loadingSubmit,
    retry: retrySubmit,
    submitWithDuplicates,
    warnings,
  } = useSubmitJob({
    jobType,
    onAPIError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    onJobError: (errors) => {
      setJobErrors(errors);
    },
    onSuccess: (j) => {
      setJob(j);
      getStreamQuery({
        variables: {
          isAdmin: account?.permissions?.admin || false,
          slug: stream.slug,
          userCode: account?.userCode || '',
        },
      });
    },
    onTimeout: (toStatus: EJobStatus) =>
      setTimeoutType(toStatus === EJobStatus.Complete ? 'complete' : 'submit'),
    stream,
  });

  const handleUpload = (files: FileList | null) => {
    if (files != null && files.length > 0) {
      setWorksheet(files.item(0));
    }
  };

  const getEmptyWorksheetURL = () =>
    job?.lifeCycle.find((t) => t.to === EJobStatus.InProgress)?.worksheet.httpURL;

  const isLoading = loadingStart || loadingSubmit;

  const getMessage = () => {
    if (timeoutType === 'checkout') {
      return (
        <p>
          We&apos;re experiencing issues downloading your worksheet on your computer. Please try
          again later.
        </p>
      );
    }

    if (jobType === EJobType.AddLosses) {
      return (
        <>
          <p>
            {shouldDownloadWorksheet && 'We downloaded an Excel spreadsheet to your computer.'} Add
            as much data as you like and then upload it here when you’re done.
          </p>
          <p>
            If the file’s gone missing,{' '}
            <a
              data-testid="add-losses-link"
              href={getEmptyWorksheetURL()}
              rel="noopener noreferrer"
              target="_blank"
            >
              download a new copy
            </a>
            .
          </p>
        </>
      );
    }
    return (
      <p>
        {shouldDownloadWorksheet && 'We just downloaded a worksheet to your computer.'} Add any data
        about as many properties as you like, then upload it there when you are done. (If the
        worksheet has gone missing,{' '}
        <a
          data-testid="add-losses-link"
          href={getEmptyWorksheetURL()}
          rel="noopener noreferrer"
          target="_blank"
        >
          download a new copy
        </a>
        .)
      </p>
    );
  };

  const renderActionButton = () => {
    const label = timeoutType ? 'Retry' : !!warnings ? 'Add with duplicates' : submitLabel;
    if (
      job?.status === EJobStatus.Complete ||
      timeoutType === 'complete' ||
      timeoutType === 'submit'
    ) {
      return <Button fill label="Close" onClick={closeFlyout} />;
    }
    return (
      <Button
        data-testid="m-m-add-submit"
        fill
        iconSide="right"
        disabled={timeoutType !== 'checkout' && (!job || !worksheet)}
        label={label}
        loading={isLoading}
        onClick={() => {
          sendMixpanelEvent(tracker, `Submit ${jobType} excel worksheet`, stream);
          if (!timeoutType && !warnings) {
            setJobErrors(null);
            submit(job?.id as string, worksheet as File);
          } else if (warnings) {
            submitWithDuplicates(job?.id as string, worksheet as File);
          } else if (timeoutType === 'checkout') {
            retryCheckout();
          } else if (timeoutType === 'submit') {
            retrySubmit();
          }
          setTimeoutType(null);
        }}
      />
    );
  };

  const renderTimeoutMessage = () => {
    if (timeoutType !== 'submit' && timeoutType !== 'complete') {
      return null;
    }

    return (
      <EuiFormRow>
        <CallOut type="warning" title="Pending changes">
          <EuiText>
            Your worksheet was uploaded successfully. We are processing it and you will receive an
            email once the system finished {timeoutType === 'submit' && 'validating and '}applying
            all changes.
          </EuiText>
        </CallOut>
      </EuiFormRow>
    );
  };
  return (
    <AnimatedPanel type="primary">
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <h2>{title}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexGroup>
          {loadingStart ? (
            <LoadingSpinnerV2 />
          ) : (
            <EuiFlexItem>
              <EuiText>{getMessage()}</EuiText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        <Spacer />
        <EuiForm>
          {!loadingStart && timeoutType !== 'checkout' && (
            <EuiFormRow label="Upload Completed Worksheet">
              <EuiFilePicker data-testid="m-m-add-file-picker" onChange={handleUpload} />
            </EuiFormRow>
          )}
          {renderTimeoutMessage()}
          {!!jobErrors && (
            <EuiFormRow>
              <JobErrorsCallout jobErrors={jobErrors} />
            </EuiFormRow>
          )}
          {!!warnings && <DuplicatePropertyIDsCallout warnings={warnings} />}
          {job?.status === EJobStatus.Complete && showAffectedProperties && properties.length > 0 && (
            <EuiFormRow>
              <CallOut type="success" title="Added your properties">
                <EuiText>
                  The following properties have been added. Click on any of them to open property
                  modal. It may take up to 20 minutes for the property grid and explorers to update:
                </EuiText>
                <Spacer />
                <EuiListGroup
                  listItems={properties.map((p) => ({
                    href: propertiesModalPathname(stream, params, p.id),
                    label: p.locationName || p.locationId || p.archipelagoId,
                    target: '_blank',
                  }))}
                />
              </CallOut>
            </EuiFormRow>
          )}
        </EuiForm>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>{renderActionButton()}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </AnimatedPanel>
  );
};

export default AddJobPanel;
