import React, { useEffect } from 'react';
import { EuiHorizontalRule } from 'ui';
import { AttributeLocks, Property } from '@app/graphql/types';
import { TrackGroupSovManager, useTracker } from '@app/hooks/useTracker';
import { useStreamContext } from '../../StreamProvider';
import { TabContentGroup } from '../AddPropertyFlyout.emotion';
import { useAddPropertyFlyoutContext } from '../context/AddPropertyFlyout.context';
import { AddressAttributes } from './AddressAttributes';
import { CurrencyAttributes } from './CurrencyAttributes';

export interface IAttributesTabProps {
  attributeLock?: AttributeLocks;
  property: Property;
}

export const RequiredAttributesTab = () => {
  const { stream } = useStreamContext();
  const { property } = useAddPropertyFlyoutContext();
  const tracker = useTracker();
  useEffect(() => {
    if (!stream) {
      return;
    }
    const payload: any = {
      event_surface: TrackGroupSovManager.event_surface,
      organization_name: stream?.orgName,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    };

    if (!!property) {
      const { archipelagoId, locationId, locationName } = property;
      payload.archipelago_id = archipelagoId;
      payload.location_id = locationId;
      payload.location_name = locationName;
    }

    tracker.track(`${TrackGroupSovManager.prefix}: Open required attributes tab`, payload);
  }, [stream?.slug, property?.archipelagoId]);

  return (
    <TabContentGroup direction="column">
      <AddressAttributes />
      <EuiHorizontalRule />
      <CurrencyAttributes />
    </TabContentGroup>
  );
};
