import { Dispatch, SetStateAction } from 'react';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import usePermanentlyDeletePropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/usePermanentlyDeletePropertiesPanel';
import useRestorePropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRestorePropertiesPanel';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { useStreamContext } from '../StreamProvider';
import { PanelItem } from './types';
import useAddPropertiesPanel from './useAddPropertiesPanel';
import useDisposePropertiesPanel from './useDisposePropertiesPanel';
import useEditPropertiesPanel from './useEditPropertiesPanel';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

export default ({ inProgressJobs, setOpen, refetch }: Props): PanelItem => {
  const { permissions, stream } = useStreamContext();
  const { selectedOrganization } = useUserSession();
  const tracker = useTracker();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  const AddPropertiesPanel = useAddPropertiesPanel({ inProgressJobs, setOpen });
  const DisposePropertiesPanel = useDisposePropertiesPanel({ setOpen });
  const EditPropertiesPanel = useEditPropertiesPanel({
    inProgressJobs,
    refetch,
    setOpen,
  });
  const PermanentlyDeletePropertyPanel = usePermanentlyDeletePropertiesPanel({ setOpen });
  const RestorePropertiesPanel = useRestorePropertiesPanel({ setOpen });

  const propertiesGroupItems = [];
  if (permissions.canEditProperties) {
    propertiesGroupItems.push(EditPropertiesPanel);
  }
  if (permissions.canAddProperties) {
    propertiesGroupItems.push(AddPropertiesPanel);
  }
  if (permissions.canEditProperties) {
    propertiesGroupItems.push(DisposePropertiesPanel);
  }

  // Only available for admins for now
  if (isAdmin) {
    propertiesGroupItems.push(PermanentlyDeletePropertyPanel);
  }

  // Available for RMs in SOVM7 or admins pre-SOVM7
  if (permissions.canEditProperties || isAdmin) {
    propertiesGroupItems.push(RestorePropertiesPanel);
  }

  const onClick = () => {
    sendMixpanelEvent(tracker, 'Open Properties panel', stream);
  };

  return {
    'data-testid': 'manage-properties-button',
    iconName: 'property',
    label: 'Properties',
    nestedPanel: {
      items: propertiesGroupItems,
      title: 'Properties',
    },
    onClick,
  };
};
