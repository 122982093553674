import React, { useEffect, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import moment from 'moment';
import { Button, EuiSpacer, EuiTitle } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useUserSession } from '@app/contexts/UserSessionContext';
import {
  OrganizationActivitiesQuery,
  useOrganizationActivitiesQuery,
} from '@app/graphql/queries/organizationActivities/__generated__/organizationActivities.generated';
import { useTracker } from '@app/hooks/useTracker';
import { EventsByDate } from './EventsByDate';
import { inRange } from 'lodash';

export const EventsContainer = () => {
  const { selectedOrganization } = useUserSession();
  const [items, setItems] = useState<
    OrganizationActivitiesQuery['organizationActivities']['activities']
  >([]);
  const apiTracker = useTracker();

  useEffect(() => {
    apiTracker.track('View Activity Log', {
      event_surface: 'Activity Log',
      organization_id: selectedOrganization?.id,
    });
  }, []);

  const { data, loading, refetch, networkStatus } = useOrganizationActivitiesQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setItems((prevState) => [...prevState, ...data?.organizationActivities?.activities]);
    },
    variables: {
      input: {
        cursor: null,
        limit: 50,
        organizationName: selectedOrganization.name,
      },
    },
  });

  const loadMore = async () => {
    await refetch({
      input: {
        cursor: data?.organizationActivities?.cursor,
        limit: 50,
        organizationName: selectedOrganization.name,
      },
    });
  };

  const handleScroll = async (e) => {
    const scrolledContent = Math.round(e.target.scrollHeight - e.target.scrollTop);
    const container = Math.round(e.target.clientHeight);

    const bottom = inRange(container, scrolledContent - 20, scrolledContent + 20);
    if (bottom && data?.organizationActivities?.cursor) {
      await loadMore();
    }
  };

  const eventsByDate = items
    .filter((item) => {
      if (item.eventName.includes('Edit')) {
        return item.attributes && item.attributes.length !== 0 && item.propertiesCount !== 0;
      }
      return item.eventName.includes('Edit') || item.eventName.includes('Add');
    })
    .reduce((acc, item) => {
      const date = moment(item.timestamp).format('MMM Do, YYYY');
      if (acc[date]) {
        acc[date].push(item);
      } else {
        acc[date] = [item];
      }

      return acc;
    }, {});

  const isLoading =
    loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <EuiSpacer size="l" />
      <EuiTitle>
        <h2>Activity Log</h2>
      </EuiTitle>
      <EuiSpacer size="xl" />
      <div style={{ height: '60vh', overflow: 'auto' }} onScroll={async (e) => handleScroll(e)}>
        {Object.entries(eventsByDate).map((obj, index) => (
          <EventsByDate key={index} events={obj[1]} date={obj[0]} />
        ))}

        {!isLoading && data?.organizationActivities?.cursor && (
          <Button label="Load More" onClick={loadMore} />
        )}
      </div>
      <EuiSpacer size="xl" />
      {isLoading && (
        <div style={{ width: '300px' }}>
          <LoadingSpinnerV2 />
        </div>
      )}
    </>
  );
};
