import React from 'react';
import { useToast } from 'ui';
import { useSelection } from '@app/contexts/SelectionContext/SelectionContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useApplyDeletePropertiesJobInputMutation } from '@app/graphql/jobs/mutations/__generated__/applyDeletePropertiesJob.generated';
import { useTracker } from '@app/hooks/useTracker';
import { AccordionTable } from '../AccordionTable/AccordionTable';
import { ModalWithButtons } from '../ModalWithButtons/ModalWithButtons';
import { sendMixpanelEvent } from '../PropertiesDataGrid/utils';

const tableKeys = ['locationName', 'locationId', 'archipelagoId'];

const tableKeysToDisplayName = {
  archipelagoId: 'Archipelago ID',
  locationId: 'Client ID',
  locationName: 'Building name',
};

export const DeletePropertyModal = ({ onClose, refetch, refetchGroups }) => {
  const { selectedOrganization } = useUserSession();
  const toast = useToast();
  const orgName = selectedOrganization.name;
  const { selectedRows, updateSelectedRows } = useSelection();
  const jobsApolloClient = useJobsApolloClient();
  const effectiveSelectedRows = Object.values(selectedRows);

  const { stream } = useStreamContext();
  const tracker = useTracker();

  const label =
    effectiveSelectedRows.length > 1
      ? `Your properties (${effectiveSelectedRows.length}) have been deleted.`
      : 'Your property has been deleted.';

  const [applyDeletePropertiesJobMutation, { loading: isDeleting }] =
    useApplyDeletePropertiesJobInputMutation({
      client: jobsApolloClient,
      onCompleted: () => {
        refetch();
        updateSelectedRows({ action: 'clear' });
        toast({ label, title: 'Success' });
      },
      onError: (e) => {
        const errorLabel =
          effectiveSelectedRows.length > 1
            ? ` ${
                e.message.includes('already being edited')
                  ? 'The selected properties are currently being edited in another job and cannot be deleted. Please finish the in progress job before trying again.'
                  : `There was an error deleting properties (${effectiveSelectedRows.length})`
              }`
            : `  ${
                e.message.includes('already being edited')
                  ? 'The selected property is currently being edited in another job and cannot be deleted. Please finish the in progress job before trying again.'
                  : 'There was an error deleting the property.'
              }`;
        toast({ label: `${errorLabel}`, title: 'Error', type: 'danger' });
      },
    });

  const header =
    effectiveSelectedRows.length > 1
      ? 'Delete this property?'
      : `Delete ${effectiveSelectedRows.length} properties?`;

  return (
    <ModalWithButtons
      onClose={() => {
        sendMixpanelEvent(tracker, 'Cancel delete properties', stream);
        onClose();
      }}
      buttonActionLabel="Delete"
      subtext="This will permanently remove the property data from the platform."
      header={header}
      isLoading={isDeleting}
      onClick={async () => {
        const propertyArchipelagoIDs = effectiveSelectedRows.map(
          (property) => property.archipelagoId,
        );
        sendMixpanelEvent(tracker, 'Delete properties', stream, {
          archipelagoIds: propertyArchipelagoIDs,
        });
        await applyDeletePropertiesJobMutation({
          variables: {
            input: {
              orgName,
              propertyArchipelagoIDs,
            },
          },
        });
        onClose();
      }}
      isValid
    >
      {effectiveSelectedRows.map((row) => {
        const title = `${row.streetAddress}, ${row.city}, ${row.postalCode}, ${row.country}`;
        return (
          <AccordionTable
            id={row.archipelagoId}
            title={title}
            tables={tableKeys.map((key) => ({
              label: tableKeysToDisplayName[key],
              value: row[key],
            }))}
          />
        );
      })}
    </ModalWithButtons>
  );
};
