import React, { FC, useEffect } from 'react';
import { ReportProps } from '@onarchipelago/cx/Reports/types';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from 'ui';
import { ReportContainer } from '@app/components/ReportContainer/ReportContainer';
import { useTracker } from '@app/hooks/useTracker';
import { useReportsContext } from '../contexts/reportsContext/reportsContext';
import { reportPageStyle } from '../reports.emotion';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';
import Overview from './Overview/Overview';
import RiskSummary from './RiskSummary/RiskSummary';
import Filters from './Filters';
import { UnderwritingAssessmentReportProvider } from './underwritingAssessmentReportContext';

interface UnderwritingAssessmentReportProps extends Omit<ReportProps, 'visible'> {}

const UnderwritingAssessmentReport: FC<UnderwritingAssessmentReportProps> = () => {
  const tracker = useTracker();

  const { snapshots, stream } = useReportsContext();
  const { currentSnapshot } = snapshots;
  const { streamId, streamName, streamSlug, ...streamRest } = stream;

  useEffect(() => {
    tracker.track('Open Report', {
      report_name: 'UAR Report',
      snapshot: currentSnapshot?.snapshotName,
      stream_id: streamId,
      stream_name: streamName,
      stream_slug: streamSlug,
      ...streamRest,
      ...snapshots,
    });
  }, []);

  return (
    <EuiFlexGroup
      direction="column"
      responsive={false}
      style={reportPageStyle}
      data-testid="underwritingassessment-report"
    >
      <ReportsHeader header="Risk Assessment Report" testId="ua-report-header" />
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <Filters />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex', height: 184 }}>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={2}>
              <Overview />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <ReportContainer>
        <RiskSummary />
      </ReportContainer>
    </EuiFlexGroup>
  );
};

const UnderwritingAssessmentReportWithContext: FC<ReportProps> = ({ visible }) => (
  <>
    {visible && (
      <UnderwritingAssessmentReportProvider>
        <UnderwritingAssessmentReport />
      </UnderwritingAssessmentReportProvider>
    )}
  </>
);

export default UnderwritingAssessmentReportWithContext;
