import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import RoleChecker from '@onarchipelago/cx/RoleChecker/RoleChecker';
import { ContextMenu, EuiFlexGroup, Icon } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { ModalContext } from '@app/contexts/ModalContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_SMART_DOCS_OFF } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { StyledListGroup } from '../Layout/SideNav/SideNav.emotion';

export const AdminSettingsDropdown: FC<{
  isOpen: boolean;
  isCollapsed: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen, isCollapsed }) => {
  const tracker = useTracker();
  const { showModal } = useContext(ModalContext);
  const history = useHistory();

  const { selectedOrganization } = useContext(UserSessionContext);

  const orgName = selectedOrganization?.name || '';

  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  const canViewJobs = isAdmin;

  const onMenuButtonClick = () => setIsOpen((val: boolean) => !val);
  const closePopover = () => setIsOpen(false);

  const button = (
    <StyledListGroup
      listItems={[
        {
          'data-test-subj': 'admin-settings-menu-item',
          icon: (
            <EuiFlexGroup>
              <Icon name="gear" />
            </EuiFlexGroup>
          ),
          label: isCollapsed ? null : 'Admin Settings',
          onClick: onMenuButtonClick,
        },
      ]}
    />
  );

  let documents = [
    {
      'data-testid': 'smartfolders-menu-item',
      iconName: 'folder',
      label: 'Smartfolders',
      onClick: () => {
        closePopover();
        history.push({ pathname: encodeUrl`/organizations/${orgName}/smartfolders` });
      },
    },
    {
      'data-testid': 'documents-v2-menu-item',
      iconName: 'folder',
      label: 'Docs Admin',
      onClick: () => {
        closePopover();
        history.push({ pathname: encodeUrl`/organizations/${orgName}/documents-admin` });
      },
    },
  ];
  /**
   * logic reversed for smartDocs FF
   * @see https://app.shortcut.com/onarchipelago/story/133786/reverse-smartdocs-feature-flag-logic-and-enable-smartdocs-for-all-orgs
   */
  const smartDocsDisabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SMART_DOCS_OFF,
  );

  if (!smartDocsDisabled) {
    documents = [
      {
        'data-testid': 'smartDocs-menu-item',
        iconName: 'folder',
        label: 'SmartDocs',
        onClick: () => {
          closePopover();
          history.push({ pathname: encodeUrl`/organizations/${orgName}/documents` });
        },
      },
    ];
  }

  const jobItems = [
    {
      'data-testid': 'my-jobs-menu-item',
      iconName: 'briefcase',
      label: 'My Jobs',
      onClick: () => {
        closePopover();
        history.push({ pathname: '/jobs' });
      },
    },
  ];

  const supportItem = {
    'data-testid': 'support-menu-item',
    iconName: 'help',
    label: 'Support',
    onClick: () => {
      closePopover();
      history.push({ pathname: '/support' });
    },
  };

  const adminItems = [
    {
      'data-testid': 'role-checker-menu-item',
      iconName: 'userCheck',
      label: 'Role Checker',
      onClick: () => {
        closePopover();
        showModal(<RoleChecker />);
      },
    },
    ...documents,
    {
      'data-testid': 'organizations-menu-item',
      iconName: 'globe',
      label: 'Accounts',
      onClick: () => {
        closePopover();
        history.push({ pathname: '/organizations' });
      },
    },
    {
      'data-testid': 'upload-portal-menu-item',
      iconName: 'uploadCloud',
      label: 'Upload Portal',
      onClick: () => {
        closePopover();
        history.push({ pathname: '/upload' });
        tracker.track('Open Upload Portal', { ...selectedOrganization });
      },
    },
    {
      'data-testid': 'system-menu-item',
      iconName: 'gear',
      label: 'System Settings',
      onClick: () => {
        closePopover();
        history.push({ pathname: '/system' });
      },
    },
  ];

  const items = [];
  if (canViewJobs) {
    items.push(...jobItems);
  }

  items.push(supportItem);

  if (isAdmin) {
    items.push(...adminItems);
  }

  return (
    <ContextMenu
      anchorPosition="downRight"
      button={button}
      onClose={closePopover}
      open={isOpen}
      panel={{ items }}
    />
  );
};
