import React from 'react';
import { useHistory } from 'react-router';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { TrackGroupMarketAccess, useTracker } from '@app/hooks/useTracker';
import { PanelItem } from './types';

export default (): PanelItem => {
  const { stream } = useStreamContext();
  const history = useHistory();
  const tracker = useTracker();

  return {
    'data-testid': 'market-access-button',
    iconName: 'users',
    label: 'Market Access',
    onClick: () => {
      tracker.track(
        `${TrackGroupMarketAccess.prefix}: Manage ${STREAMS_LABEL} -> Market Access clicked`,
        {
          streamSlug: stream?.slug,
        },
      );
      history.push({ pathname: encodeUrl`/streams/${stream?.slug}/domains` });
    },
  };
};
