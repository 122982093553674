import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { Button, EuiFlexGroup, EuiFlexItem, EuiToolTip } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { ModalContext } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { PrecheckRecommendationsButton } from '@app/cx/Stream/EditModal/Tabs/SnapshotsTab/PrecheckRecommendationsButton';
// FIX ME
// @ts-ignore

export const RegisterUserForm = ({ stream }) => {
  const { selectedOrganization } = useUserSession();

  const history = useHistory();
  const { closeModal } = useContext(ModalContext);

  return (
    <div>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem
              grow={false}
              // FIX ME
              // @ts-ignore
              direction="row"
            >
                <Button
                  label={`View ${STREAMS_LABEL}'s precheck`}
                  onClick={() => {
                    closeModal();
                    history.push({
                      pathname: encodeUrl`/organizations/${selectedOrganization.name}/precheck/`,
                    });
                  }}
                />
            </EuiFlexItem>
            <PrecheckRecommendationsButton streamSlug={stream.slug} />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
