import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  FEATURE_TYPE_AON_DEMO,
  FEATURE_TYPE_DOCUMENTS_PORTAL,
  FEATURE_TYPE_SIMPLIFIED_SOV,
} from '@onarchipelago/platform/SystemSettings/Flags/types';
import { Location } from 'history';
import {
  Asset,
  Button,
  ButtonIcon,
  ContextMenu,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiPage,
  EuiPageBody,
  EuiThemeProvider,
  Tabs,
  useEuiTheme,
} from 'ui';
import { SUBMISSIONS_PATH_BASE } from '@app/containers/App/Routes/constants';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { getAccountSubmissionOrg, userHasSubmissionAccess } from '@app/cx/Dashboard/helpers';
import { OrgSwitcherFlyout } from '@app/cx/OrgSwitcher/OrgSwitcherFlyout/OrgSwitcherFlyout';
import { useTracker } from '@app/hooks/useTracker';
import { useWhiteLabel } from '@app/hooks/useWhiteLabel/useWhiteLabel';
import { useInboxContext } from '@app/platform/Inbox/context/InboxContext';
// @ts-ignore
import GET_STREAMS from '@app/queries/streams/getStreams.gql';
import { GetStreamsResponse } from '@app/queries/streams/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { CASUALTY_PATH, SUPPORT_PATH } from './SideNav/constants';
import { SideNav } from './SideNav/SideNav';
import { TopNav } from './TopNav/TopNav';
import {
  CASUALTY_TAB,
  HOME_TAB,
  INBOX_PATH,
  INBOX_TAB,
  LIBRARY_TAB,
  PRECHECK_TAB,
  PROJECTS_TAB,
  STREAMS_TAB,
  SUBMISSIONS_TAB,
  SUPPORT_TAB,
  USERS_TAB,
} from './constants';
import HeaderUserMenu from './HeaderUserMenu';
import {
  GlobalHeader,
  HeaderLogo,
  IconContainer,
  MainContainer,
  newNavPageStyle,
} from './Layout.emotion';

export const tabToPath = (tab: string, selectedOrgName?: string) => {
  if (tab === SUPPORT_PATH) {
    return `/${tab}`;
  }

  // Admins/people with multiple orgs
  if (selectedOrgName) {
    const toPath = (items: string[]) => `/${items.map((s: string) => encodeUrl`${s}`).join('/')}`;
    return toPath(['organizations', selectedOrgName || '', tab.toLowerCase()]);
  }
  return `/${tab.toLowerCase()}`;
};

export const pathToTab = (location: Location) => {
  if (location.pathname.includes('/library')) {
    return LIBRARY_TAB;
  }
  if (location.pathname.includes('/projects')) {
    return PROJECTS_TAB;
  }
  if (location.pathname.includes('/users')) {
    return USERS_TAB;
  }
  if (location.pathname.includes('/inbox')) {
    return INBOX_TAB;
  }
  if (location.pathname.includes(SUBMISSIONS_PATH_BASE)) {
    return SUBMISSIONS_TAB;
  }
  if (location.pathname.includes('precheck')) {
    return PRECHECK_TAB;
  }
  if (location.pathname.includes('home')) {
    return HOME_TAB;
  }

  if (location.pathname.includes('/support')) {
    return SUPPORT_TAB;
  }

  if (location.pathname.includes('/casualty')) {
    return CASUALTY_TAB;
  }
  return STREAMS_TAB;
};

const LayoutElastic: FC = ({ children }) => {
  const { euiTheme } = useEuiTheme();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const { showFlyout } = useContext(FlyoutContext);
  const { selectedOrganization } = useContext(UserSessionContext);
  const { inbox } = useInboxContext();
  // FIX ME
  // @ts-ignore
  const { themeOverride, assetName, logoStyle } = useWhiteLabel(account);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canViewProjects = permissions.includes('canManageProjects');
  const canManageUsers =
    selectedOrganization?.enabledFeatures?.includes('SelfServiceUserMgmt') &&
    (isAdmin || permissions.includes('canManageUsers'));
  const canViewAccounts = permissions?.includes('canViewAccounts');
  const canManageProperties = permissions?.includes('canManageProperties');

  const canViewDocuments =
    isAdmin ||
    (selectedOrganization?.enabledFeatures?.includes(FEATURE_TYPE_DOCUMENTS_PORTAL) &&
      permissions.includes('canManageDocuments'));

  const isSimplifiedSOVEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SIMPLIFIED_SOV,
  );

  // Temporary styles until we remove Material UI
  const bodyStyle = document?.querySelector('body')?.style as any;
  if (bodyStyle?.display) {
    bodyStyle.backgroundColor = '#fafbfd';
    bodyStyle.fontFamily = 'inherit';
    bodyStyle.fontWeight = 'inherit';
    bodyStyle.fontSize = 'inherit';
  }

  const history = useHistory();
  const location = useLocation();
  const tracker = useTracker();

  const [selectedTab, setSelectedTab] = useState(pathToTab(location));
  useEffect(() => {
    const newTab = pathToTab(location);
    if (selectedTab.path !== newTab.path) {
      setSelectedTab(newTab);
    }
  }, [location.pathname]);

  const onSelectedTabChanged = (val: { id: string; label: string; path: string }) => {
    setSelectedTab(val);
    const context: any = {};

    if (val.id === 'precheck') {
      context.event_surface = 'PreCheck';
      context.stream_id = stream.id;
      context.stream_slug = stream.slug;
      context.organization_id = selectedOrganization.id;
    }

    tracker.track(`${val.label} Tab Clicked`, context);

    history.push({
      pathname: tabToPath(val.path, selectedOrganization?.name),
    });
  };
  const submissionOrgs = account?.submissionCreateOrgs || [];

  const hasSubmissionAccessForCurrentOrg = userHasSubmissionAccess(
    submissionOrgs,
    selectedOrganization,
  );

  // true if primary org is submission org, else false
  const primaryOrgIsSubmissionOrg = !!getAccountSubmissionOrg(account);

  // Logic to handle initial tab selection for underwriters and admins
  const initialTab =
    (isAdmin || hasSubmissionAccessForCurrentOrg) &&
    ((selectedOrganization?.name === account.organizationName &&
      !location.pathname.includes('/streams')) ||
      location.pathname.includes('/submissions'))
      ? SUBMISSIONS_TAB
      : pathToTab(location);

  /* Conditions for enabling the submissions dash (one of the following must be true):
  1. User is an admin and the selected org has the submission dashboard
  2. User is not an admin but has access to the selected org, and the selected org has the submissions dash enabled
  3. The user is not an admin, the primary org has the submissions dashboard enabled, and they will be redirected to their primary org's submissions dash
  4. The user is a RiskManager or EnterpriseAdmin and the `canViewAccounts` setting is true
  */

  const enableAccountDashboard =
    (isAdmin && selectedOrganization?.enableAccountDashboard) ||
    hasSubmissionAccessForCurrentOrg ||
    (primaryOrgIsSubmissionOrg && !isAdmin) ||
    canViewAccounts;

  const { data } = useQuery<
    GetStreamsResponse,
    {
      orgName: string | null;
    }
  >(GET_STREAMS, {
    skip: Boolean(!selectedOrganization?.enablePreCheck),
    variables: {
      orgName: selectedOrganization?.name,
    },
  });

  const stream = data?.streamsV2?.streams?.find((s) => s?.slug?.match(/-my-properties/i));
  const aonDemoEnabled = selectedOrganization?.enabledFeatures?.includes(FEATURE_TYPE_AON_DEMO);

  const tabs = [
    STREAMS_TAB, // Everyone gets streams
    ...(enableAccountDashboard ? [SUBMISSIONS_TAB] : []),
    ...(selectedOrganization?.enablePreCheck && stream
      ? [
          {
            ...PRECHECK_TAB,
          },
        ]
      : []),
    ...(canViewDocuments ? [LIBRARY_TAB] : []),
    ...(canViewProjects ? [PROJECTS_TAB] : []),
    ...(canManageUsers ? [USERS_TAB] : []),
    ...((selectedOrganization?.enablePreCheck || aonDemoEnabled) && canManageProperties
      ? [INBOX_TAB]
      : []),
  ];

  const finalTabs = tabs.map((tab) => ({
    badgeText: tab.id === INBOX_PATH ? inbox?.all?.length + inbox?.mentions?.length : undefined,
    'data-testid': `header-${tab.id}-tab`,
    href: tab.path === null ? null : tabToPath(tab.path),
    id: tab.id,
    label: tab.label,
    onClick: (e: any) => {
      // Don't follow link!
      e.preventDefault();

      onSelectedTabChanged(tab);
    },
  }));

  const helpButton = (
    <Button
      fontWeight={600}
      minWidth="70px"
      label="Help"
      iconName="help"
      size="s"
      shape="oval"
      aria-label="help button"
      color="primary"
      onClick={() => {
        setIsPopoverOpen(!isPopoverOpen);
      }}
    />
  );

  return (
    <EuiThemeProvider modify={themeOverride}>
      <MainContainer data-testid="app-layout">
        {isSimplifiedSOVEnabled ? (
          <>
            <TopNav />
            <SideNav />
          </>
        ) : (
          <GlobalHeader position="static">
            <HeaderLogo {...logoStyle} isOldUI side="left">
              <Asset name={assetName} color={euiTheme.colors.primary} />
              <Tabs
                initialTabId={initialTab.id}
                // FIX ME
                // @ts-ignore
                tabs={finalTabs}
                size="l"
                bottomBorder={false}
              />
            </HeaderLogo>
            <EuiHeaderSection side="right">
              <IconContainer border="none">
                <ContextMenu
                  button={helpButton}
                  anchorPosition="downCenter"
                  panel={{
                    items: [
                      {
                        label: 'User guide',
                        onClick: () => {
                          window.open('https://docs.onarchipelago.com/', '_blank', 'noopener');
                          setIsPopoverOpen(false);
                        },
                      },
                    ],
                  }}
                  open={isPopoverOpen}
                  onClose={() => {
                    setIsPopoverOpen(false);
                  }}
                />
              </IconContainer>
              <EuiHeaderSectionItem border="none">
                <HeaderUserMenu showOrgSwitcher={() => showFlyout(<OrgSwitcherFlyout />)} />
              </EuiHeaderSectionItem>
            </EuiHeaderSection>
          </GlobalHeader>
        )}
        <EuiPage style={isSimplifiedSOVEnabled ? newNavPageStyle : {}}>
          <EuiPageBody component="div">{children}</EuiPageBody>
        </EuiPage>
      </MainContainer>
    </EuiThemeProvider>
  );
};

export default LayoutElastic;
