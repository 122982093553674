import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ApolloError, ApolloQueryResult, QueryLazyOptions } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import isNil from 'lodash/isNil';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiText,
  Tabs,
  useToast,
} from 'ui';
import { checkIsOwnerAttribute, sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { ColumnKey } from '@app/components/PropertiesGrid/types';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { SelectedRows } from '@app/contexts/SelectionContext/SelectionContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import {
  PropertiesPageProvider,
  usePropertiesPageContext,
} from '@app/cx/Properties/PropertiesPage.context';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { JobStatus } from '@app/graphql/jobs/jobs.types';
import { useApplyAddPropertyJobMutation } from '@app/graphql/jobs/mutations/__generated__/applyAddPropertyJob.generated';
import { useApplyEditPropertyJobMutation } from '@app/graphql/jobs/mutations/__generated__/applyEditPropertyJob.generated';
import { StreamPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import {
  AttributeLocks,
  Exact,
  PropertyDataInput,
  StreamPropertiesPageInput,
} from '@app/graphql/types';
import { useJobPoller } from '@app/hooks/useJobPoller';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_SIMPLIFIED_SOV } from '@app/platform/SystemSettings/Flags/types';
import {
  IGetPropertyGroupsData,
  IGetPropertyGroupsVariables,
} from '@app/queries/streams/PropertyGroupsQuery/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { StreamProvider, useStreamContext } from '../StreamProvider';
import { AdditionalAttributesTab } from './AdditionalAttributesTab/AdditionalAttributesTab';
import { CancelModal } from './CancelModal/CancelModal';
import {
  AddPropertyFlyoutProvider,
  useAddPropertyFlyoutContext,
} from './context/AddPropertyFlyout.context';
import { DocumentsTab } from './DocumentsTab/DocumentsTab';
import { RequiredAttributesTab } from './RequiredAttributesTab/RequiredAttributesTab';
import {
  ADDRESS_INPUT_KEYS,
  CONSUMER_ATTRIBUTES_KEY,
  CUSTOM_ATTRIBUTES_KEY,
  LAT_INPUT_KEY,
  LNG_INPUT_KEY,
  LOSS_ATTRIBUTES_KEY,
  OWNER_ATTRIBUTES_KEY,
  TAB_IDS,
} from './constants';
import { hasAddressFields, hasGeoFields, makeOriginalAddressField } from './utils';
import { yupSchema } from './yupSchema';

const MATERIALIZED_VIEW_DISCLAIMER =
  ' Note that it may take a few hours for these changes to be included in the overview totals.';
interface IAddPropertyFlyoutProps {
  selectedRows?: SelectedRows;
  refetch?: (
    variables?: Partial<
      Exact<{
        input: StreamPropertiesPageInput;
      }>
    >,
  ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>;
  refetchGroups?: (
    variables?: IGetPropertyGroupsVariables,
  ) => Promise<ApolloQueryResult<IGetPropertyGroupsData>>;
  refetchSelected?: (
    options?: QueryLazyOptions<
      Exact<{
        input: StreamPropertiesPageInput;
      }>
    >,
  ) => void;
  attributeLocks?: AttributeLocks[];
}

const AddPropertyFlyoutContent = ({
  selectedRows,
  refetch,
  refetchGroups,
  refetchSelected,
  attributeLocks,
}: IAddPropertyFlyoutProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [propertyIndex, setPropertyIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState<string>(TAB_IDS.REQUIRED_TAB_ID);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { closeFlyout } = useFlyout();
  const selected = Object.values(selectedRows || {});
  const isEditMode = !!selected?.length;
  const toast = useToast();
  const tracker = useTracker();
  const { selectedOrganization } = useUserSession();
  const isSimplifiedSOVEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SIMPLIFIED_SOV,
  );
  const { stream, propertyAttributeMetadata } = isSimplifiedSOVEnabled
    ? usePropertiesPageContext()
    : useStreamContext();
  const jobsApolloClient = useJobsApolloClient();
  const [applyAddPropertyJob] = useApplyAddPropertyJobMutation({
    client: jobsApolloClient,
  });

  const {
    isClientIdConfirmed,
    isClientIdDuplicate,
    selectedGoogleAddress,
    customerProvidedGeocode,
    documentIds,
    setProperty,
    setPropertyAttributeLocks,
  } = useAddPropertyFlyoutContext();

  const selectedProperty = selected?.[propertyIndex];

  setProperty(selectedProperty);

  //filters out derived attributes
  const validFields = propertyAttributeMetadata
    // FIX ME
    // @ts-ignore
    ?.filter((attribute) => !attribute.derived)
    ?.map((attribute) => attribute.name);

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    if (selectedProperty && formMethods && propertyAttributeMetadata?.length) {
      const defaultValues = selectedProperty ? { ...selectedProperty } : {};

      // loss attributes are not editable
      delete defaultValues[LOSS_ATTRIBUTES_KEY];
      // consumer attributes are also not editable
      delete defaultValues[CONSUMER_ATTRIBUTES_KEY];

      // flatten owner attributes
      if (defaultValues[OWNER_ATTRIBUTES_KEY]) {
        Object.entries(defaultValues[OWNER_ATTRIBUTES_KEY]).forEach(([key, value]) => {
          defaultValues[key] = value;
        });
      }
      delete defaultValues[OWNER_ATTRIBUTES_KEY];
      Object.keys(defaultValues).forEach((key) => {
        if (!validFields.includes(key as ColumnKey)) {
          delete defaultValues[key];
        }
      });
      formMethods.reset({
        ...defaultValues,
      });
    }
  }, [selectedProperty, formMethods, propertyAttributeMetadata]);

  const trackEvent = (close: boolean) => {
    const trackPayload: any = {
      organization_name: stream?.orgName,
    };
    if (isEditMode) {
      trackPayload.properties = selected.map(({ archipelagoId, locationId, locationName }) => ({
        archipelago_id: archipelagoId,
        location_id: locationId,
        location_name: locationName,
      }));
    }

    sendMixpanelEvent(
      tracker,
      `${close ? 'Cancel' : 'Start'} ${isEditMode ? 'edit' : 'add'} property`,
      // FIX ME
      // @ts-ignore
      stream,
      trackPayload,
    );
  };
  useEffect(() => {
    if (!!stream) {
      trackEvent(false);
    }
  }, [stream?.slug]);

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const disableSubmit = !isValid || (isClientIdDuplicate && !isClientIdConfirmed);
  const attributeLock = attributeLocks?.find(
    (lock) => lock?.propertyArchipelagoID === selectedProperty?.archipelagoId,
  );
  setPropertyAttributeLocks(attributeLock);
  const tabs = [
    {
      content: <RequiredAttributesTab />,
      id: TAB_IDS.REQUIRED_TAB_ID,
      label: 'Required Attributes',
      onClick: () => {
        setSelectedTab(TAB_IDS.REQUIRED_TAB_ID);
      },
    },
    {
      content: <AdditionalAttributesTab />,
      id: TAB_IDS.ADDITIONAL_TAB_ID,
      label: 'Additional Attributes',
      onClick: () => {
        setSelectedTab(TAB_IDS.ADDITIONAL_TAB_ID);
      },
    },
    {
      content: (
        <DocumentsTab id={selectedProperty?.id} archipelagoId={selectedProperty?.archipelagoId} />
      ),
      id: TAB_IDS.DOCUMENTS_TAB_ID,
      label: 'Documents',
      onClick: () => {
        setSelectedTab(TAB_IDS.DOCUMENTS_TAB_ID);
      },
    },
  ];

  const onSuccessfulAddEdit = () => {
    toast({
      title: isEditMode
        ? `Property successfully edited. ${MATERIALIZED_VIEW_DISCLAIMER}`
        : `Property successfully added. ${MATERIALIZED_VIEW_DISCLAIMER}`,
    });
    if (refetch) {
      refetch();

      if (refetchGroups) {
        refetchGroups();
      }
      if (refetchSelected) {
        refetchSelected();
      }
    }
    closeFlyout();
  };

  const onError = (error: ApolloError) => {
    toast({
      title: `Error while ${isEditMode ? 'adding' : 'editing'} property: ${error.message}`,
      type: 'danger',
    });
  };

  const onTimeout = () => {
    toast({
      title: `${
        isEditMode ? 'Add' : 'Edit'
      } property job is taking longer than expected. Please check the page later for your changes.`,
      type: 'danger',
    });
  };

  const [applyEditPropertyJob] = useApplyEditPropertyJobMutation({
    client: jobsApolloClient,
  });

  const { startGetJobPoll, isPolling } = useJobPoller(onSuccessfulAddEdit, {
    interval: 2000,
    onError,
    onTimeout,
    retryCount: 30,
    targetStatus: [JobStatus.PropertyUpdatesCompleted],
  });

  const submitHandler: SubmitHandler<PropertyDataInput & { locationId: string }> = async (data) => {
    setIsSubmitting(true);
    try {
      const filteredData = { ...data };
      Object.entries(filteredData).forEach(([key, value]) => {
        if (value === '' || isNil(value)) {
          delete filteredData[key];
        }
      });
      const locationId = filteredData?.locationId || undefined;

      if (locationId) {
        delete filteredData['locationId'];
      }

      // handle owner attributes
      Object.entries(filteredData).forEach(([key, value]) => {
        if (checkIsOwnerAttribute(key)) {
          if (!filteredData[CUSTOM_ATTRIBUTES_KEY]) {
            filteredData[CUSTOM_ATTRIBUTES_KEY] = {
              [key]: value,
            };
          } else {
            filteredData[CUSTOM_ATTRIBUTES_KEY][key] = value;
          }

          delete filteredData[key];
        }
      });

      // use originalAddress if the google autocomplete value was not used.
      if (!selectedGoogleAddress && hasAddressFields(filteredData)) {
        const originalAddress = makeOriginalAddressField(filteredData);
        filteredData.originalAddress = originalAddress;
        ADDRESS_INPUT_KEYS.forEach((key) => delete filteredData[key]);
      }

      const result = await applyAddPropertyJob({
        variables: {
          input: {
            customerProvidedGeocode,
            linkedDocumentIDs: documentIds,
            locationId,
            orgName: selectedOrganization.name,
            propertyData: filteredData,
            streamSlug: stream.slug,
          },
        },
      });
      startGetJobPoll(result?.data?.applyAddPropertyJob?.id);
    } catch (error) {
      toast({ title: `Could not add property: ${error.message}`, type: 'danger' });
    }
    setIsSubmitting(false);
  };

  const fieldsToRemove = new Set([
    'replacementCostPerSquareFootageDisplay',
    'buildingReplacementCostDisplay',
    'contentsReplacementCostDisplay',
    'businessInterruptionCostDisplay',
    'totalInsuredValueDisplay',
    'archipelagoId',
    'id',
    '__typename',
  ]);

  const editHandler: SubmitHandler<PropertyDataInput & { locationId: string }> = async (data) => {
    setIsSubmitting(true);
    try {
      const filteredData = { ...data };

      // clean up by deleting locked and unchanged attributes
      Object.entries(filteredData).forEach(([key, _value]) => {
        if (
          fieldsToRemove.has(key) ||
          !validFields.includes(key as ColumnKey) ||
          (!!attributeLock && attributeLock?.attributeNames?.includes(key)) ||
          selectedProperty[key] === filteredData[key] // no changes from original value
        ) {
          delete filteredData[key];
        }
      });

      // rebuild ownerAttributes object from flattened attributes but under customAttributes key
      Object.entries(filteredData).forEach(([key, value]) => {
        if (checkIsOwnerAttribute(key)) {
          if (value !== selectedProperty?.[OWNER_ATTRIBUTES_KEY]?.[key]) {
            if (!filteredData[CUSTOM_ATTRIBUTES_KEY]) {
              filteredData[CUSTOM_ATTRIBUTES_KEY] = {
                [key]: value,
              };
            } else {
              filteredData[CUSTOM_ATTRIBUTES_KEY][key] = value;
            }
          }
          delete filteredData[key];
        }
      });

      // add back in all address keys in case they haven't changed and were filtered out above.
      if (hasAddressFields(filteredData) || (hasGeoFields(filteredData) && selectedGoogleAddress)) {
        if (selectedGoogleAddress) {
          ADDRESS_INPUT_KEYS.forEach((key) => {
            filteredData[key] = data[key];
          });
        } else {
          const originalAddress = makeOriginalAddressField(filteredData);
          filteredData.originalAddress = originalAddress;
          // remove all address inputs since we only want originalAddress
          ADDRESS_INPUT_KEYS.forEach((key) => {
            delete filteredData[key];
          });
        }
      }

      // handles locationId
      const locationID = filteredData?.locationId || undefined;
      if (locationID) {
        delete filteredData['locationId'];
      }
      const attributeNames = Object.keys(filteredData);

      try {
        // FIX ME
        // @ts-ignore
        sendMixpanelEvent(tracker, 'Submit property edit', stream, {
          property_id: selectedProperty?.archipelagoId,
        });
        const { data: newEditJob } = await applyEditPropertyJob({
          variables: {
            input: {
              attributeNames,
              customerProvidedGeocode:
                filteredData[LAT_INPUT_KEY] && filteredData[LNG_INPUT_KEY]
                  ? Boolean(!selectedGoogleAddress)
                  : false,
              linkedDocumentIDs: documentIds,
              locationID,
              orgName: selectedOrganization.name,
              propertyArchipelagoID: selectedProperty?.archipelagoId,
              propertyData: filteredData,
              streamSlug: stream?.slug,
            },
          },
        });
        const jobID = newEditJob.applyEditPropertyJob.id;
        if (isLastProperty) {
          startGetJobPoll(jobID);
        } else {
          setPropertyIndex(propertyIndex + 1);
          setSelectedTab(TAB_IDS.REQUIRED_TAB_ID);
        }
      } catch (error) {
        toast({
          label: error.message,
          sticky: true,
          title: 'Could not update property',
          type: 'danger',
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      toast({ title: `Could not edit property: ${error.message}`, type: 'danger' });
    }
    setIsSubmitting(false);
  };

  const isLastProperty = propertyIndex + 1 === selected?.length;

  const header = isEditMode
    ? `Edit property (${propertyIndex + 1}/${selected?.length})`
    : 'Add a property';

  const partialButtonText = isLastProperty ? 'return to Stream' : 'go to next property';

  const buttonText = isEditMode ? `Update and ${partialButtonText}` : 'Add property';
  const onClose = () => {
    setIsCancelModalOpen(true);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <EuiForm
          id="add-properties-form"
          component="form"
          onSubmit={handleSubmit(isEditMode ? editHandler : submitHandler)}
        >
          <EuiFlyout maxWidth="460px" onClose={onClose}>
            <EuiFlyoutHeader>
              <EuiText>
                <h2>{header}</h2>
              </EuiText>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
              <Tabs initialTabId={TAB_IDS.REQUIRED_TAB_ID} selectedTab={selectedTab} tabs={tabs} />
            </EuiFlyoutBody>
            <EuiFlyoutFooter>
              <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                <EuiFlexItem grow={0}>
                  <ButtonEmpty label="Cancel" onClick={onClose} />
                </EuiFlexItem>
                <EuiFlexItem grow={0}>
                  <Button
                    label={buttonText}
                    type="submit"
                    form="add-properties-form"
                    disabled={disableSubmit}
                    loading={isSubmitting || isPolling}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlyoutFooter>
          </EuiFlyout>
        </EuiForm>
      </FormProvider>
      {isCancelModalOpen && (
        <CancelModal
          isEditMode={isEditMode}
          onCancel={() => setIsCancelModalOpen(false)}
          onConfirm={() => {
            trackEvent(true);
            closeFlyout();
          }}
        />
      )}
    </>
  );
};

export const AddPropertyFlyout = ({
  selectedRows,
  refetch,
  refetchGroups,
  refetchSelected,
  attributeLocks,
}: IAddPropertyFlyoutProps) => {
  const { selectedOrganization } = useUserSession();

  const content = (
    <AddPropertyFlyoutProvider>
      <AddPropertyFlyoutContent
        selectedRows={selectedRows}
        refetch={refetch}
        refetchGroups={refetchGroups}
        refetchSelected={refetchSelected}
        attributeLocks={attributeLocks}
      />
    </AddPropertyFlyoutProvider>
  );

  return (
    <PropertiesPageProvider>
      <StreamProvider>{content}</StreamProvider>
    </PropertiesPageProvider>
  );
};
