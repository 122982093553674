import { useEffect, useRef, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { IJob } from '@onarchipelago/dice/EnrichmentProjects/types';
import moment from 'moment';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import CHECKOUT_JOB from '@app/queries/organizations/checkoutJob.gql';
// FIX ME
// @ts-ignore
import CREATE_JOB from '@app/queries/organizations/createJob.gql';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import { START_ADD_LOSSES_JOB } from '@app/queries/organizations/startAddLossesJob';
import {
  CheckoutJobData,
  CheckoutJobVariables,
  EJobStatus,
  EJobType,
  ICreateJobData,
  ICreateJobVariables,
  IGetJobData,
  IGetJobVariables,
  StartAddLossesJobData,
  StartAddLossesJobVariables,
} from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
import { IGraphQLStream } from '@app/queries/streams/types';
import { AddJobType } from './types';

export const getStartJobMutation = (jobType: AddJobType) =>
  jobType === EJobType.AddLosses ? START_ADD_LOSSES_JOB : CREATE_JOB;

export const getStartJobVariables = (
  jobType: AddJobType,
  orgName: string,
  streamSlug: string,
  userId?: string,
): ICreateJobVariables | StartAddLossesJobVariables => {
  if (jobType === EJobType.AddLosses) {
    return {
      input: {
        orgName,
      },
    };
  }
  return {
    input: {
      doerUserID: userId,
      expireInHours: 48,
      name: `Add Properties - ${moment().format('YYYY-MM-DD')}`,
      orgName,
      reviewerUserID: userId,
      streamSlug,
      type: jobType,
      worksheetFormat: 'SingleColumn',
    },
  };
};

const getCreatedJobId = (jobType: AddJobType, data: ICreateJobData | StartAddLossesJobData) => {
  if (jobType === EJobType.AddLosses) {
    return (data as StartAddLossesJobData).startAddLossesJob.jobID;
  }

  return (data as ICreateJobData).createJob.id;
};

interface Props {
  jobType: EJobType.AddProperties | EJobType.AddLosses;
  orgName: string;
  stream: IGraphQLStream;
  defaultJob?: Lock;
  onSuccess: (job: IJob) => void;
  onError: (err: ApolloError) => void;
  onTimeout: () => void;
}

interface Return {
  loading: boolean;
  retry: () => void;
}

const RETRY_LIMIT = 10;
export default ({
  jobType,
  orgName,
  stream,
  defaultJob,
  onSuccess,
  onError,
  onTimeout,
}: Props): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobID, setJobID] = useState<string>('');
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;
  const [retryCount, setRetryCount] = useState<number>(0);

  const jobsApolloClient = useJobsApolloClient();

  const tracker = useTracker();
  // ----- CHECKOUT JOB MUTATION ----- //

  const retry = async (callback: Function) => {
    if (retryCount + 1 >= RETRY_LIMIT) {
      setIsLoading(false);
      onTimeout();
      return;
    }

    const retryTime = 3000;
    timerRef.current = setTimeout(() => callback(), retryTime);

    setRetryCount(retryCount + 1);
  };

  const [getJobQuery] = useLazyQuery<IGetJobData, IGetJobVariables>(GET_JOB, {
    client: jobsApolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIsLoading(false);
      onSuccess(data.job);
    },
    onError: (error) => {
      setIsLoading(false);
      onError(error);
    },
    variables: {
      jobID,
    },
  });

  const [getStreamQuery] = useLazyQuery(GET_STREAM, {
    variables: {
      isAdmin,
      slug: stream.slug,
      userCode: account?.userCode || '',
    },
  });

  const [checkoutJobMutation] = useMutation<CheckoutJobData, CheckoutJobVariables>(CHECKOUT_JOB, {
    client: jobsApolloClient,
    onCompleted: () => {
      clearTimeout(timerRef.current);
      getJobQuery();
      getStreamQuery();
    },
    onError: () => {
      retry(checkoutJobMutation);
    },

    variables: {
      input: {
        jobID,
      },
    },
  });

  // ----- CREATE JOB MUTATION ----- //
  const [createJobMutation] = useMutation<
    ICreateJobData | StartAddLossesJobData,
    ICreateJobVariables | StartAddLossesJobVariables
  >(getStartJobMutation(jobType), {
    ...(jobType !== EJobType.AddLosses && { client: jobsApolloClient }),
    onCompleted: (data) => {
      setJobID(getCreatedJobId(jobType, data));

      if (jobType === EJobType.AddLosses) {
        getJobQuery();
        getStreamQuery();
      } else {
        checkoutJobMutation();
      }
    },
    onError: (error) => {
      setIsLoading(false);
      onError(error);
    },
  });

  useEffect(() => () => clearTimeout(timerRef.current), []);
  useEffect(() => {
    if (!defaultJob) {
      createJobMutation({
        variables: getStartJobVariables(jobType, orgName, stream.slug, account?.userId),
      });
    } else {
      setJobID(defaultJob.id);

      if (defaultJob.canStart) {
        checkoutJobMutation({ variables: { input: { jobID: defaultJob.id } } });
      } else {
        getJobQuery();
      }
    }
  }, [defaultJob?.id]);

  const retryCheckout = () => {
    const trackerEvent = getTrackerEvent(jobType, stream);
    tracker.track(
      `Manage Properties - ${trackerEvent.name} - Retry checkout`,
      trackerEvent.payload,
    );

    setRetryCount(0);
    setIsLoading(true);
    checkoutJobMutation();
  };

  return {
    loading: isLoading,
    retry: retryCheckout,
  };
};

const getTrackerEvent = (jobType: AddJobType, stream: IGraphQLStream) => ({
  name: jobType === EJobType.AddLosses ? 'Add Losses' : 'Add Properties',

  payload: {
    stream_id: stream.id,
    stream_name: stream.name,
    stream_slug: stream.slug,
  },
});
