import { useAssignTasksMutation } from '@app/graphql/precheck/mutations/assignTasks/__generated__/assignTasks.generated';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiPanel,
  EuiPopover,
  EuiText,
  EuiTextColor,
  useToast,
} from 'ui';
import { useTaskContext } from './context/TaskContext';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUnassignTasksMutation } from '@app/graphql/precheck/mutations/unassignTasks/__generated__/unassignTasks.generated';
import { StyledButton, StyledDisclaimer, StyledDiv } from './Assignment.emotion';
import { IFormattedtask } from './context/types';
import { useTracker } from '@app/hooks/useTracker';

interface IProps {
  type?: 'assignToMe' | 'bulkAssign';
  tasks: Array<IFormattedtask>;
}

const getTaskEmails = (tasks) => {
  const assignees = tasks.reduce((acc, task) => {
    task.assignees.map((task) => (acc[task.email] = task.email));
    return acc;
  }, {});

  return Object.keys(assignees);
};

export const Assignment: FC<IProps> = ({ type, tasks }) => {
  const client = usePrecheckClient();

  const allUserEmails = getTaskEmails(tasks);
  const [selectedUsers, setSelectedUsers] = useState(allUserEmails);
  const { filters, setSelectedTasks, refetch } = useTaskContext();
  const { selectedOrganization } = useUserSession();
  const toast = useToast();
  const { account } = useAuth();
  const tracker = useTracker();
  const [isPopOverOpen, setIsPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedUsers(getTaskEmails(tasks));
  }, [tasks]);

  const [assignTasksMutation, { loading }] = useAssignTasksMutation({
    client,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const [unassignTasksMutation, { loading: unassignLoading }] = useUnassignTasksMutation({
    client,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const sameAssigneesOnAllTasks = getTaskEmails(tasks).every((email) =>
    tasks.every((task) => task.assignees.map((a) => a.email).includes(email)),
  );

  let userOptions = filters.userOptions;
  if (type === 'bulkAssign') {
    userOptions = userOptions.map((option) => {
      const onAllTasks = tasks.every((task) =>
        task.assignees.map((a) => a.email).includes(option.value),
      );

      if (allUserEmails.includes(option.value) && !onAllTasks) {
        return { label: `*${option.label}`, value: option.value };
      } else {
        return option;
      }
    });
  }

  const updateAssignees = (users) => {
    const currentAssignees = getTaskEmails(tasks);

    const unAssignedUsers = currentAssignees.filter((email) => !users.includes(email));
    const newAssignedUsers = users.filter(
      (user) => !currentAssignees.find((email) => email === user),
    );

    setSelectedUsers(users);

    const taskIds = tasks.map((task) => task.id);

    if (newAssignedUsers.length > 0) {
      tracker.track('Pre-Check: Users Assigned to Task', {
        taskIds,
      });
      assignTasksMutation({
        variables: {
          input: {
            orgName: selectedOrganization?.name,
            assigneeEmails: newAssignedUsers,
            taskIDs: taskIds,
          },
        },
      });
    }

    if (unAssignedUsers.length > 0) {
      tracker.track('Pre-Check: Users Unassigned to Task', {
        taskIds,
      });
      unassignTasksMutation({
        variables: {
          input: {
            orgName: selectedOrganization?.name,
            assigneeEmails: unAssignedUsers,
            taskIDs: taskIds,
          },
        },
      });
    }
  };

  const assignToMe = () => {
    tracker.track('Pre-Check: Users Assigned to Task', {
      taskIds: [tasks[0].id],
    });
    assignTasksMutation({
      variables: {
        input: {
          orgName: selectedOrganization?.name,
          assigneeEmails: [account.email],
          taskIDs: [tasks[0].id],
        },
      },
    });
  };

  if (type === 'assignToMe') {
    return (
      <EuiPanel hasShadow={false}>
        <EuiText>
          <h5>Assign this recommendation to yourself to begin editing these fields</h5>
          <StyledButton
            data-testid={'assign-to-me'}
            loading={loading}
            disabled={loading}
            onClick={assignToMe}
            label="Assign to Myself"
          />
        </EuiText>
      </EuiPanel>
    );
  }

  const renderOption = (option, searchValue) => {
    const { label } = option;

    return (
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem grow={false}>
          <Avatar name={label} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiHighlight search={searchValue}>{label}</EuiHighlight>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const MultiComponent = (
    <EuiComboBox
      data-testid={`assignment-select`}
      placeholder="Assignees"
      options={userOptions}
      isDisabled={loading || unassignLoading}
      renderOption={renderOption}
      onChange={(updatedUsers) => {
        if (loading || unassignLoading) return;
        updateAssignees(updatedUsers.map((user) => user.value));
      }}
      selectedOptions={userOptions.filter((option) => selectedUsers.includes(option.value))}
    />
  );

  if (type === 'bulkAssign') {
    return (
      <EuiPopover
        anchorPosition="rightUp"
        panelPaddingSize="xs"
        isOpen={isPopOverOpen}
        closePopover={() => {
          setSelectedTasks([]);

          setIsPopoverOpen(false);
        }}
        button={
          <Button
            fill
            data-testid="bulk-assignment"
            onClick={() => {
              setIsPopoverOpen(!isPopOverOpen);
            }}
            label={`Assign (${tasks.length})`}
          />
        }
      >
        <StyledDiv>
          {MultiComponent}

          {!sameAssigneesOnAllTasks && (
            <StyledDisclaimer data-testid="assignment-disclaimer">
              <EuiTextColor color="subdued">
                * User is not assigned to every task, un assign user first before assigning to all
                tasks
              </EuiTextColor>
            </StyledDisclaimer>
          )}
        </StyledDiv>
      </EuiPopover>
    );
  }

  return MultiComponent;
};
