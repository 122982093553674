import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import isNil from 'lodash/isNil';
import {
  Button,
  ButtonIcon,
  EuiDataGridRefProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiText,
  useToast,
} from 'ui';
import { boolean, number, object, string } from 'yup';
import PropertiesGridTooltip from '@app/components/PropertiesGrid/PropertiesGridTooltip/PropertiesGridTooltip';
import { ProvenanceType } from '@app/components/PropertiesGrid/PropertiesGridTooltip/types';
import { getEditRecords } from '@app/components/PropertiesGrid/PropertiesGridTooltip/utils';
import { getProvenanceAsObj } from '@app/components/PropertiesGrid/utils';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { usesCurrencyConversion } from '@app/cx/PropertyModal/utils';
import { getAttributeRow } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { DuplicateClientIdCallout } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/DuplicateClientIdCallout';
import {
  ADDRESS_AND_GEO_INPUT_KEYS,
  ADDRESS_INPUT_KEYS,
  AttributeDataType,
  CUSTOM_ATTRIBUTES_KEY,
  DERIVED_CURRENCY_KEYS,
  LAT_INPUT_KEY,
  LNG_INPUT_KEY,
  REQUIRED_EDIT_KEYS,
  TIV_INPUT_KEY,
} from '@app/cx/Stream/AddPropertyFlyout/constants';
import { useValidateMPData } from '@app/cx/Stream/AddPropertyFlyout/hooks/useValidateMPData';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { JobStatus } from '@app/graphql/jobs/jobs.types';
import { useApplyEditPropertyJobMutation } from '@app/graphql/jobs/mutations/__generated__/applyEditPropertyJob.generated';
import { Property } from '@app/graphql/types';
import { useJobPoller } from '@app/hooks/useJobPoller';
import { TrackGroupSovManager, useTracker } from '@app/hooks/useTracker';
import { usePropertiesDataGridContext } from '../../context/PropertiesDataGridContext';
import { CellPopoverFormValues } from '../../types';
import { checkIsOwnerAttribute } from '../../utils';
import {
  EDIT_CELL_INPUT_KEY,
  GLOBAL_CURRENCY_INPUT_KEY,
  LOCAL_CURRENCY_INPUT_KEY,
} from '../constants';
import { EditAddressPopoverContent } from './EditAddressPopoverContent';
import { EditCurrencyPopoverContent } from './EditCurrencyPopoverContent';
import { EditFormField } from './EditFormField';
import { handleAddressInputs } from './utils';
import { yupSchema } from './yupSchema';

interface IEditCellPopoverContentProps {
  dataGridRef: React.MutableRefObject<EuiDataGridRefProps>;
  property: Property;
  columnId: string;
}

export const EditCellPopoverContent = ({
  dataGridRef,
  property,
  columnId,
}: IEditCellPopoverContentProps) => {
  const { selectedOrganization } = useUserSession();
  const { propertyAttributeMetadata, stream } = useStreamContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSelectedGoogleAddress, setHasSelectedGoogleAddress] = useState(false);
  const [isClientIdDuplicate, setIsClientIdDuplicate] = useState(false);
  const [isClientIdConfirmed, setIsClientIdConfirmed] = useState(false);
  const [clientIdState, setClientIdState] = useState<string>();
  const { refetch } = usePropertiesDataGridContext();
  const toast = useToast();
  const attributeMetadata = propertyAttributeMetadata.find((meta) => meta.name === columnId);
  const currencyFieldTypes = ['currencyLocal', 'currency'];
  const isCurrencyField = currencyFieldTypes.includes(attributeMetadata.dataType);
  // Doesn't count lat/lng since we want to be able to update those individually. However the
  // lat/lng will be included in the EditAddressPopoverContent form if an address field is edited.
  const isAddressField = ADDRESS_INPUT_KEYS.includes(columnId);

  const isOwnerAttribute = checkIsOwnerAttribute(columnId);

  // Information on local currency: https://archipelago.slab.com/posts/internal-guide-properties-in-local-currency-ynxmpwuw
  const globalField = columnId.toLowerCase().includes('display') ? columnId : `${columnId}Display`;
  const localField = globalField.replace('Display', '');
  const globalFieldExists =
    globalField === columnId || propertyAttributeMetadata.find((meta) => meta.name === globalField);

  const shouldShowCurrencyConversion =
    isCurrencyField && usesCurrencyConversion(property) && globalFieldExists;

  const isRequired = shouldShowCurrencyConversion
    ? REQUIRED_EDIT_KEYS.includes(localField)
    : REQUIRED_EDIT_KEYS.includes(columnId);

  const isBooleanField = attributeMetadata.dataType === AttributeDataType.Boolean;
  const yupType = isBooleanField ? boolean : string;

  const validationRules = shouldShowCurrencyConversion
    ? {
        [GLOBAL_CURRENCY_INPUT_KEY]: isRequired ? number().required() : number().nullable(true),
        [LOCAL_CURRENCY_INPUT_KEY]: isRequired ? number().required() : number().nullable(true),
      }
    : {
        [EDIT_CELL_INPUT_KEY]: isRequired ? yupType().required() : yupType().nullable(true),
      };

  const formMethods = useForm({
    mode: 'all',
    // FIX ME
    // @ts-ignore
    resolver: yupResolver(isAddressField ? yupSchema : object(validationRules)),
  });
  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = formMethods;

  // FIX ME
  // @ts-ignore
  const formValue: string = watch(EDIT_CELL_INPUT_KEY);

  const jobsApolloClient = useJobsApolloClient();
  const [applyEditPropertyJob] = useApplyEditPropertyJobMutation({
    client: jobsApolloClient,
  });

  const { debounceValidateMPData, clientIdWarning, isValidating } = useValidateMPData();

  const tracker = useTracker();
  useEffect(() => {
    if (shouldShowCurrencyConversion) {
      // FIX ME
      // @ts-ignore
      setValue(GLOBAL_CURRENCY_INPUT_KEY, property[globalField]);
      // FIX ME
      // @ts-ignore
      setValue(LOCAL_CURRENCY_INPUT_KEY, property[localField]);
    } else if (isAddressField) {
      ADDRESS_AND_GEO_INPUT_KEYS.forEach((key) => {
        // FIX ME
        // @ts-ignore
        setValue(key, property[key]);
      });
    } else if (isOwnerAttribute) {
      // FIX ME
      // @ts-ignore
      setValue(EDIT_CELL_INPUT_KEY, property?.ownerAttributes?.[columnId]);
    } else {
      // FIX ME
      // @ts-ignore
      setValue(EDIT_CELL_INPUT_KEY, property[columnId]);
    }

    const trackerProps = {
      archipelago_id: property?.archipelagoId,
      attribute: columnId,
      event_surface: TrackGroupSovManager.event_surface,
      location_id: property?.locationId,
      location_name: property?.locationName,
      organization_name: stream?.orgName,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    };
    tracker.track(`${TrackGroupSovManager.prefix}: Open edit cell popover`, trackerProps);

    return () => {
      tracker.track(`${TrackGroupSovManager.prefix}: Close edit cell popover`, trackerProps);
    };
  }, []);

  useEffect(() => {
    if (columnId === 'locationId') {
      if (
        formValue &&
        (formValue !== clientIdState || !isClientIdConfirmed) &&
        property.locationId !== formValue
      ) {
        debounceValidateMPData(formValue);
        setClientIdState(formValue);
      } else if (!formValue) {
        setClientIdState(undefined);
        setIsClientIdDuplicate(false);
      }
    }
  }, [formValue]);

  useEffect(() => {
    if (clientIdWarning) {
      setIsClientIdDuplicate(true);
      setIsClientIdConfirmed(false);
    } else {
      setIsClientIdDuplicate(false);
    }
  }, [clientIdWarning]);

  const onEditCompleted = () => {
    refetch();
    setIsSubmitting(false);
    dataGridRef?.current?.closeCellPopover();
    toast({ title: 'Property successfully updated' });
  };

  const onError = (error: ApolloError) => {
    toast({
      label: error.message,
      sticky: true,
      title: 'Could not update property.',
      type: 'danger',
    });
    setIsSubmitting(false);
    dataGridRef?.current?.closeCellPopover();
  };

  const onTimeout = () => {
    toast({
      sticky: true,
      title:
        'Edit job is taking longer than expected. Please check the page later for your changes.',
      type: 'danger',
    });
    setIsSubmitting(false);
    dataGridRef?.current?.closeCellPopover();
  };

  const { startGetJobPoll, isPolling } = useJobPoller(onEditCompleted, {
    interval: 2000,
    onError,
    onTimeout,
    retryCount: 30,
    targetStatus: [JobStatus.PropertyUpdatesCompleted],
  });

  const onSubmit: SubmitHandler<CellPopoverFormValues> = async (formData) => {
    setIsSubmitting(true);
    let propertyData = {};
    let attributeNames = [columnId];
    let locationID: string;
    let customerProvidedGeocode = !!(columnId === LAT_INPUT_KEY || columnId === LNG_INPUT_KEY);

    if (shouldShowCurrencyConversion) {
      propertyData[localField] = formData?.[LOCAL_CURRENCY_INPUT_KEY];
      attributeNames = [localField];
    } else if (isCurrencyField) {
      if (isOwnerAttribute) {
        // need to nest owner attribute in customAttributes key
        propertyData[CUSTOM_ATTRIBUTES_KEY] = {
          [columnId]: formData?.[EDIT_CELL_INPUT_KEY],
        };
        attributeNames = [CUSTOM_ATTRIBUTES_KEY];
      } else {
        propertyData[localField] = formData?.[EDIT_CELL_INPUT_KEY];
        attributeNames = [localField];
      }
    } else if (isAddressField) {
      // want to pass the same address fields if the fields have not been changed
      ({ propertyData, attributeNames, customerProvidedGeocode } = handleAddressInputs(
        formData,
        property,
        hasSelectedGoogleAddress,
        propertyData,
        attributeNames,
      ));
    } else if (isOwnerAttribute) {
      // need to nest owner attribute in customAttributes key
      if (isBooleanField) {
        propertyData[CUSTOM_ATTRIBUTES_KEY] = {
          [columnId]: Number(formData?.[EDIT_CELL_INPUT_KEY]),
        };
      } else {
        propertyData[CUSTOM_ATTRIBUTES_KEY] = {
          [columnId]:
            formData?.[EDIT_CELL_INPUT_KEY] === undefined ? null : formData?.[EDIT_CELL_INPUT_KEY],
        };
      }
      attributeNames = [CUSTOM_ATTRIBUTES_KEY];
    } else {
      propertyData[columnId] = isNil(formData[EDIT_CELL_INPUT_KEY])
        ? null
        : formData[EDIT_CELL_INPUT_KEY];
    }

    // calculates TIV if necessary.
    if (isCurrencyField && DERIVED_CURRENCY_KEYS.includes(localField)) {
      const newTivValue = DERIVED_CURRENCY_KEYS.reduce((acc, value) => {
        if (localField === value) {
          return acc + Number(propertyData[localField]);
        }
        return acc + (Number(property[value]) || 0);
      }, 0);
      propertyData[TIV_INPUT_KEY] = newTivValue;
      attributeNames.push(TIV_INPUT_KEY);
    }

    try {
      const { data: newEditJob } = await applyEditPropertyJob({
        variables: {
          input: {
            attributeNames,
            customerProvidedGeocode,
            locationID,
            orgName: selectedOrganization.name,
            propertyArchipelagoID: property?.archipelagoId,
            propertyData,
            streamSlug: stream.slug,
          },
        },
      });
      const jobId = newEditJob.applyEditPropertyJob.id;
      startGetJobPoll(jobId);
    } catch (error) {
      toast({
        label: error.message,
        sticky: true,
        title: 'Could not update property',
        type: 'danger',
      });
      setIsSubmitting(false);
      dataGridRef?.current?.closeCellPopover();
    }
  };

  const { dataType } = attributeMetadata;

  const inputField =
    dataType === 'currency' || dataType === 'currencyLocal' ? (
      <EditFormField type={dataType} />
    ) : (
      // FIX ME
      // @ts-ignore
      getAttributeRow({ ...attributeMetadata, displayName: null, name: EDIT_CELL_INPUT_KEY })
    );

  const provenance = getProvenanceAsObj(property?.attributeProvenance || []);
  const editInfo = getEditRecords(property, columnId);

  const hasTitleTooltip = provenance[columnId]?.sources || !!editInfo?.length;
  const streamContext = {
    stream_id: stream?.id,
    stream_name: stream?.name,
    stream_slug: stream?.slug,
  };
  const title = (
    <EuiText style={{ textDecoration: hasTitleTooltip ? 'underline' : undefined }}>
      <h4>Edit {isAddressField ? 'Address' : attributeMetadata?.displayName || 'Cell'}</h4>
    </EuiText>
  );
  return (
    <FormProvider {...formMethods}>
      <EuiPopoverTitle>
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          {hasTitleTooltip ? (
            <PropertiesGridTooltip
              dataTestId="inline-edit-properties-data-grid-tooltip"
              handleDocumentDownload={(doc) => {
                tracker.track('Download Document', {
                  download_type: ProvenanceType.Attribute,
                  event_surface: 'property_modal',
                  filename: doc.filename,
                  url: doc.httpURL,
                  ...streamContext,
                });
              }}
              provenance={provenance[columnId]}
              editInfo={editInfo}
              handleOpen={() => {
                tracker.track('Open Provenance', {
                  ...streamContext,
                  attribute: columnId,
                });
              }}
            >
              {title}
            </PropertiesGridTooltip>
          ) : (
            title
          )}
          <ButtonIcon iconName="x" onClick={() => dataGridRef?.current?.closeCellPopover()} />
        </EuiFlexGroup>
      </EuiPopoverTitle>
      <EuiForm id="add-properties-form" component="form" onSubmit={handleSubmit(onSubmit)}>
        {shouldShowCurrencyConversion && <EditCurrencyPopoverContent property={property} />}
        {isAddressField ? (
          <EditAddressPopoverContent
            property={property}
            setHasSelectedGoogleAddress={setHasSelectedGoogleAddress}
          />
        ) : (
          inputField
        )}
        {isClientIdDuplicate && !isClientIdConfirmed && (
          <EuiFlexItem style={{ marginTop: '10px' }}>
            <DuplicateClientIdCallout
              message={clientIdWarning?.message}
              onClick={() => setIsClientIdConfirmed(true)}
            />
          </EuiFlexItem>
        )}
        <EuiPopoverFooter>
          <Button
            label="Save"
            type="submit"
            form="add-properties-form"
            disabled={!isValid || (isClientIdDuplicate && !isClientIdConfirmed)}
            loading={isSubmitting || isPolling || isValidating}
            fullWidth
          />
        </EuiPopoverFooter>
      </EuiForm>
    </FormProvider>
  );
};
