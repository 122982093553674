import React, { useContext, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  useToast,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import DataExports from '@app/cx/DocumentsModal/DataExports';
import Document from '@app/cx/DocumentsModal/Document';
import DocumentsGroup from '@app/cx/DocumentsModal/DocumentsGroup';
import styles from '@app/cx/DocumentsModal/DocumentsModalContents.emotion';
import DownloadAllDocs from '@app/cx/DocumentsModal/DownloadAllDocs';
import { IGroupedDocuments } from '@app/cx/DocumentsModal/types';
import UploadButton from '@app/cx/DocumentsModal/UploadButton';
// FIX ME
// @ts-ignore
import UPLOAD_DOCUMENT from '@app/cx/DocumentsModal/uploadDocument.gql';
import { getCodingData, groupDocuments, useDocuments } from '@app/cx/DocumentsModal/utils';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import useToggleableFunction from '@app/hooks/useToggleableFunction';
import { TrackStreamPageDocumentModal, useTracker } from '@app/hooks/useTracker';
import { IGraphQLDocument } from '@app/queries/documents/types';
import { getFileURL } from '@app/utils/download';

const showDataExportsFor = [ExportCode.AIR, ExportCode.RMS, ExportCode.ARCHIPELAGO];
const GROUP_NAMES_TO_FILTER: Array<string> = ['All docs zip', 'Data exports'];

type Upload = (file: File) => Promise<void>;

interface IGetSeparatedGroupsResponse<Doc extends IGraphQLDocument> {
  [index: string]: Array<IGroupedDocuments<Doc>>;
  groupedDocuments: Array<IGroupedDocuments<Doc>>;
}
function getSeparatedGroups<Doc extends IGraphQLDocument>(
  streamId: string,
  documents: Array<Doc>,
  namesToFilter: Array<string>,
): IGetSeparatedGroupsResponse<Doc> {
  return groupDocuments<Doc>(streamId, documents).reduce(
    (obj, groupedDocuments) => {
      if (namesToFilter.includes(groupedDocuments.name)) {
        return {
          ...obj,
          [groupedDocuments.name]: [groupedDocuments],
        };
      }

      return {
        ...obj,
        groupedDocuments: obj.groupedDocuments.concat(groupedDocuments),
      };
    },
    {
      groupedDocuments: [] as Array<IGroupedDocuments<Doc>>,
    },
  );
}

export const DocumentsTab = () => {
  const { account } = useAuth();
  const { stream, marketVisibleToggle, marketVisibleSnapshots } = useStreamContext();

  const apiTracker = useTracker();
  const { loading, documents, refetch } = useDocuments(stream.slug, '', stream.orgName);
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const toast = useToast();
  const { setOrgByName } = useUserSession();

  useEffect(() => {
    // The org is set so that the client knows which org the user was last in if it is redirected
    // to the file route.
    if (stream?.orgName) {
      setOrgByName(stream?.orgName);
    }
  }, []);

  const [upload, uploading] = useToggleableFunction<Upload>(async (file: File) => {
    if (!stream.organizationId) {
      throw new Error(
        'No organization ID exists on stream, file should not be allowed to be uploaded.',
      );
    }

    apiTracker.track(`${TrackStreamPageDocumentModal.prefix}: Upload Document`, {
      event_surface: TrackStreamPageDocumentModal.event_surface,
      fileName: file.name,
      stream_id: stream.id,
      stream_name: stream.name,
      stream_slug: stream.slug,
    });

    await uploadDocument({
      variables: {
        document: file,
        notify: true,
        orgID: stream.organizationId,
        streamID: stream.id,
      },
    })
      .then((result) => {
        apiTracker.track('Document uploaded', {
          documentId: result?.data?.upload?.id,
          event_surface: 'DocumentsTab',
          organizationId: stream.organizationId,
          url: window.location.href,
          urlPathname: window.location.pathname,
        });
      })
      .catch(() => {
        toast({ title: 'Could not upload document. Please try again.', type: 'danger' });
      });
    refetch();
  });

  const { groupedDocuments, ...separatedGroupedDocuments } = useMemo(
    () => getSeparatedGroups(stream.id, documents, GROUP_NAMES_TO_FILTER),
    [documents.map(({ id }) => id).join(',')],
  );

  const dataExports = separatedGroupedDocuments['Data exports']?.[0];

  const mixpanelDownloadEvent = (document: IGraphQLDocument) => (e: React.MouseEvent) => {
    e.stopPropagation();
    apiTracker.track(`${TrackStreamPageDocumentModal.prefix}: Download Document`, {
      download_type: 'stream_single',
      event_surface: TrackStreamPageDocumentModal.event_surface,
      filename: document.filename,
      stream_id: stream.id,
      stream_name: stream.name,
      stream_slug: stream.slug,
      url: getFileURL(document.id),
    });
  };

  const downloadAllStream = {
    ...stream,
    defaultSnapshot: marketVisibleToggle ? marketVisibleSnapshots[0].name : stream.defaultSnapshot,
  };

  return (
    <>
      <EuiModalHeader data-testid="documents-modal-header">
        <EuiModalHeaderTitle className={styles.titleContainer}>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiText>
                <h2>Documents</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <UploadButton
                    account={account}
                    upload={upload}
                    disabled={!stream.organizationId}
                    uploading={uploading}
                    marketVisibleToggle={marketVisibleToggle}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <DownloadAllDocs stream={downloadAllStream} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody data-testid="documents-modal-body">
        {loading ? (
          <LoadingSpinnerV2 />
        ) : (
          <EuiFlexGroup direction="column" gutterSize="xl">
            <EuiFlexItem grow={false}>
              <DataExports streamSlug={stream.slug} streamId={stream.id} useDefaultSnapshot>
                {({ handleClick }) => (
                  <EuiFlexGroup direction="column">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        <h3>Data Exports</h3>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiFlexGroup className={styles.documentsContainer}>
                        {(stream.allowedExports || [])
                          .filter((v) => showDataExportsFor.includes(v))
                          .map((format) => {
                            const formatData = getCodingData(format);
                            const { name, extension, exportCode } = formatData;
                            return (
                              <EuiFlexItem grow={false}>
                                <Document
                                  key={format}
                                  name={name}
                                  exportCode={exportCode}
                                  handleClick={() => handleClick(formatData)}
                                  extension={extension}
                                  testId={name}
                                  tourId={name}
                                  isExportFile
                                  stream={stream}
                                  useDefaultSnapshot
                                  marketVisibleSnapshots={marketVisibleSnapshots}
                                  marketVisibleToggle={marketVisibleToggle}
                                />
                              </EuiFlexItem>
                            );
                          })}
                        {dataExports?.documents.map((document, key) => (
                          <EuiFlexItem grow={false}>
                            <Document
                              key={key}
                              name={`${document.filename}.${document.extension}`}
                              time={document.createdAt}
                              href={getFileURL(document.id)}
                              extension={document.extension}
                              testId={document.filename}
                              tourId={document.filename}
                              handleClick={mixpanelDownloadEvent(document)}
                              isExportFile
                              stream={stream}
                              marketVisibleToggle={marketVisibleToggle}
                              marketVisibleSnapshots={marketVisibleSnapshots}
                              useDefaultSnapshot
                            />
                          </EuiFlexItem>
                        ))}
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </DataExports>
            </EuiFlexItem>
            {groupedDocuments.map((group) => (
              <EuiFlexItem grow={false} key={group.name}>
                <DocumentsGroup name={group.name}>
                  {group.documents.map((document, key) => (
                    <Document
                      key={key}
                      name={`${document.filename}.${document.extension}`}
                      time={document.createdAt}
                      href={getFileURL(document.id)}
                      extension={document.extension}
                      testId={document.filename}
                      tourId={document.filename}
                      handleClick={mixpanelDownloadEvent(document)}
                      isExportFile={marketVisibleToggle}
                      marketVisibleToggle={marketVisibleToggle}
                      marketVisibleSnapshots={marketVisibleSnapshots}
                      stream={stream}
                    />
                  ))}
                </DocumentsGroup>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        )}
      </EuiModalBody>
    </>
  );
};
