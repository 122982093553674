import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import useAxios from 'axios-hooks';
import {
  Badge,
  ButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiPanel,
  EuiTab,
  EuiTabs,
  EuiTitle,
  useToast,
} from 'ui';
import { COPILOT_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { JobStatus } from '@app/graphql/jobs/jobs.types';
import { useApproveJobMutation } from '@app/graphql/jobs/mutations/__generated__/approveJob.generated';
import { useStartProvenanceEnrichmentMutation } from '@app/graphql/jobs/mutations/__generated__/startProvenanceEnrichment.generated';
import { useSubmitProvenanceEnrichmentMutation } from '@app/graphql/jobs/mutations/__generated__/submitProvenanceEnrichment.generated';
import { useUpdateProvenanceJobDataMutation } from '@app/graphql/jobs/mutations/__generated__/updateProvenanceJobData.generated';
import { useGetJobsQuery } from '@app/graphql/jobs/queries/__generated__/getJobs.generated';
import { useProvenanceJobDataQuery } from '@app/graphql/jobs/queries/__generated__/provenanceJobData.generated';
import { GET_JOBS_QUERY } from '@app/graphql/jobs/queries/getJobs';
import { PROVENANCE_JOB_DATA_QUERY } from '@app/graphql/jobs/queries/provenanceJobData';
import { usePropertiesByAiDsQuery } from '@app/graphql/queries/property/__generated__/propertiesByAiDs.generated';
import { useGetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';
import { JobType } from '@app/graphql/types';
import { useQueryState } from '@app/hooks/useQueryState';
import { useTracker } from '@app/hooks/useTracker';
import { SourceDocument } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/types';
// @ts-ignore
import GET_SOV_PROPERTY from '@app/queries/properties/sovProperty.gql';
import { SOVPropertyData, SOVPropertyVariables } from '@app/queries/properties/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { Helmet } from '../Helmet';
import { Provider } from './context/context';
import { EnrichmentTab } from './tabs/EnrichmentTab/EnrichmentTab';
import { FullPropertyReport } from './tabs/FullPropertyTab/FullPropertyTab';
import { ArchipelagoFormatSearch } from './tabs/PropertyTab/ArchipelagoFormatSearch';
import { QueryTab } from './tabs/QueryTab/QueryTab';
import { SourceViewer } from './tabs/SourceViewer/SourceViewer';
import { CopilotContainer } from './Copilot.emotion';
import {
  AnalyzeData,
  AnalyzeVariables,
  AttributeProvenanceInput,
  defaultCopilotWorkspace,
  IWorkspace,
  IWorkspaceConfig,
  PropertyReport,
} from './types';
import { Workspace } from './Workspace';

interface WorkspaceTab {
  id: string;
  label: string;
  collapsable?: boolean;
  type: 'Default' | 'Document';
  metadata?: any;
}
const DEFAULT_TABS: Array<WorkspaceTab> = [
  { id: 'workspace1', label: 'Workspace 1', type: 'Default' },
  { id: 'workspace2', label: 'Workspace 2', type: 'Default' },
  { id: 'compare', label: 'Compare', type: 'Default' },
  { id: 'query', label: 'Query', type: 'Default' },
  { id: 'fullReport', label: 'Full Report', type: 'Default' },
  { id: 'enrichment', label: 'Update Job', type: 'Default' },
];

const WORKSPACE_CONFIG_MAP = {
  workspace1: 'copilot-ws1-normal.yaml',
  workspace2: 'copilot-ws2-deep.yaml',
};

const CONFIG_WORKSPACE_MAP = {
  'copilot-ws1-normal.yaml': 'workspace1',
  'copilot-ws2-deep.yaml': 'workspace2',
};

export const Copilot: React.FC = () => {
  const jobsApolloClient = useJobsApolloClient();
  const toast = useToast();
  const { account } = useAuth();
  const eventTracker = useTracker();
  const [tabs, setTabs] = useState(DEFAULT_TABS);
  const { selectedOrganization } = useUserSession();
  const [qs] = useQueryState();
  const initialProperty = qs.get('archipelagoID');
  const initialAttribute = qs.get('attributeName');

  const [workspaceData, setWorkspaceData] = useState<IWorkspaceConfig>(defaultCopilotWorkspace);
  const { tabNavigationStack } = workspaceData;
  const currentTab = tabNavigationStack[tabNavigationStack.length - 1];

  const [{ data, loading, error }, analyze] = useAxios<AnalyzeData, AnalyzeVariables>(
    { method: 'POST', url: `${COPILOT_URL}/analyze` },
    { autoCancel: false, manual: true },
  );

  const getJobsVariables = {
    jobsFilter: {
      activeOnly: true,
      type: JobType.EnrichmentWithProvenance,
      userID: account?.userId,
    },
  };

  const { data: jobsData } = useGetJobsQuery({
    client: jobsApolloClient,
    variables: getJobsVariables,
  });

  const jobs = jobsData?.jobsV2?.jobs;
  const propertyArchipelagoID = workspaceData.searchFormValues.propertyArchipelagoID;

  const [getSOVPropertyQuery, sovPropertyQuery] = useLazyQuery<
    SOVPropertyData,
    SOVPropertyVariables
  >(GET_SOV_PROPERTY, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const propertiesByAIDsQuery = usePropertiesByAiDsQuery({
    fetchPolicy: 'cache-first',
    skip: !propertyArchipelagoID,
    variables: { input: { archipelagoIds: [propertyArchipelagoID] } },
  });

  const propertyData = propertiesByAIDsQuery?.data;
  const property = propertyData?.propertiesByAiDs?.properties?.[0];

  const { loading: loadingStreams, data: streamsData } = useGetStreamsQuery({
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !property,
    variables: { orgName: property?.orgName },
  });

  useEffect(() => {
    if (!property?.id) {
      return;
    }

    const mpStream = streamsData?.streamsV2?.streams?.find((s) => s.isMyProperties);
    if (!!mpStream) {
      getSOVPropertyQuery({ variables: { propertyId: property.id, slug: mpStream.slug } });
    }
  }, [JSON.stringify(streamsData?.streamsV2), property?.id]);

  useEffect(() => {
    if (!!propertyArchipelagoID) {
      propertiesByAIDsQuery.refetch({ input: { archipelagoIds: [propertyArchipelagoID] } });
    }
  }, [propertyArchipelagoID]);

  const provenanceEnrichmentJob = jobs?.find(
    (j) => j?.propertyIDs?.length === 1 && j?.propertyIDs?.[0] === propertyArchipelagoID,
  );

  const [updateProvenanceJobData] = useUpdateProvenanceJobDataMutation({
    client: jobsApolloClient,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const [startProvenanceEnrichment] = useStartProvenanceEnrichmentMutation({
    client: jobsApolloClient,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const { data: provenanceJobData, loading: loadingProvenanceJobData } = useProvenanceJobDataQuery({
    client: jobsApolloClient,
    skip: !provenanceEnrichmentJob,
    variables: { jobID: provenanceEnrichmentJob?.id },
  });

  const [approveJobMutation] = useApproveJobMutation({
    client: jobsApolloClient,
    onCompleted: (data) => {
      toast({ title: 'Succesfully applied extracted values' });
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const [submitProvenanceEnrichmentMutation] = useSubmitProvenanceEnrichmentMutation({
    client: jobsApolloClient,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const addToProvenanceJob = async (attributeProvenances: Array<AttributeProvenanceInput>) => {
    let job = provenanceEnrichmentJob;
    const jobData = provenanceJobData;

    if (!job) {
      const startProvenanceEnrichmentResult = await startProvenanceEnrichment({
        variables: {
          input: {
            doerUserID: account.userId,
            name: 'Eigen enrichment - ' + propertyArchipelagoID,
            orgName: property?.orgName,
            projectName: 'Bound',
            propertyIDs: [propertyArchipelagoID],
            reviewerUserID: account.userId,
          },
        },
      });

      if (!!startProvenanceEnrichmentResult) {
        job = startProvenanceEnrichmentResult.data.startProvenanceEnrichment;
      }
    }

    if (!job) {
      return;
    }

    const attributeComments = [];
    const provenance = [];
    const attributeData = {};
    attributeProvenances.forEach(({ attributeName, documentID, value }) => {
      attributeComments.push({ attributeName, comments: ['Sourced from Eigen'] });
      provenance.push({ attributeName, documentID });
      attributeData[attributeName] = value;
    });

    updateProvenanceJobData({
      refetchQueries: [
        { query: GET_JOBS_QUERY, variables: getJobsVariables },
        { query: PROVENANCE_JOB_DATA_QUERY, variables: { jobID: job?.id } },
      ],
      variables: {
        input: {
          jobID: job.id,
          properties: [
            {
              attributeComments,
              attributeData,
              propertyID: propertyArchipelagoID,
              provenance,
            },
          ],
        },
      },
    });
  };

  const removeFromProvenanceEnrichment = (attributeName: string) => {
    updateProvenanceJobData({
      refetchQueries: [
        { query: GET_JOBS_QUERY, variables: getJobsVariables },
        { query: PROVENANCE_JOB_DATA_QUERY, variables: { jobID: provenanceEnrichmentJob?.id } },
      ],
      variables: {
        input: {
          jobID: provenanceEnrichmentJob?.id,
          properties: [
            { attributeData: { [attributeName]: null }, propertyID: propertyArchipelagoID },
          ],
        },
      },
    });
  };
  const submitProvenanceEnrichment = async () => {
    if (provenanceEnrichmentJob?.status === JobStatus.ReadyForReview) {
      approveJobMutation({ variables: { input: { jobID: provenanceEnrichmentJob?.id } } });
    } else {
      await submitProvenanceEnrichmentMutation({
        variables: { input: { jobID: provenanceEnrichmentJob?.id } },
      });
      approveJobMutation({ variables: { input: { jobID: provenanceEnrichmentJob?.id } } });
    }
  };

  const [propertyReportsQuery, fetch] = useAxios<Array<PropertyReport>>(
    { method: 'GET', url: `${COPILOT_URL}/property/${propertyArchipelagoID}` },
    { manual: true },
  );

  const partialUpdateWorkspaceSettings = (id: string, payload: Partial<IWorkspace>) => {
    setWorkspaceData((w) => ({
      ...w,
      [id]: { ...w[id], ...payload },
    }));
  };

  useEffect(() => {
    setWorkspaceData({
      ...workspaceData,
      searchFormValues: {
        ...workspaceData.searchFormValues,
        attributeName: initialAttribute,
        propertyArchipelagoID: initialProperty,
      },
    });
  }, [initialProperty, initialAttribute]);

  useEffect(() => {
    if (propertyArchipelagoID) {
      fetch();
    }
  }, [propertyArchipelagoID]);

  useEffect(() => {
    if (data) {
      const workspaceID = CONFIG_WORKSPACE_MAP[data.config];
      partialUpdateWorkspaceSettings(workspaceID, { analyzeData: data });
    }
  }, [data]);

  useEffect(() => {
    setWorkspaceData((w) => ({
      ...w,
      workspace1: { ...w?.workspace1, analyzeData: null },
      workspace2: { ...w?.workspace2, analyzeData: null },
    }));
  }, [
    workspaceData?.searchFormValues?.propertyArchipelagoID,
    workspaceData?.searchFormValues?.attributeName,
  ]);
  useEffect(() => {
    if (error) {
      toast({
        label: error?.response?.data,
        title: `An error occurred (${error?.response?.status})`,
        type: 'danger',
      });
    }
  }, [error]);

  const renderTabs = () => {
    switch (currentTab) {
      case 'compare':
        return (
          <>
            <Workspace id="workspace1" configFile={WORKSPACE_CONFIG_MAP.workspace1} />
            <Workspace id="workspace2" configFile={WORKSPACE_CONFIG_MAP.workspace2} />
          </>
        );

      case 'workspace2':
        return <Workspace id="workspace2" configFile={WORKSPACE_CONFIG_MAP.workspace2} />;

      case 'workspace1':
        return <Workspace id="workspace1" configFile={WORKSPACE_CONFIG_MAP.workspace1} />;

      case 'query':
        return <QueryTab />;

      case 'fullReport':
        return <FullPropertyReport />;
      case 'enrichment':
        return <EnrichmentTab />;
    }

    const tab = tabs.find(({ id }) => id === currentTab);
    if (!tab) {
      return null;
    }

    if (tab.type === 'Document') {
      return <SourceViewer source={tab.metadata as SourceDocument} />;
    }
  };

  const pushTab = (tab: string) => {
    setWorkspaceData({
      ...workspaceData,
      tabNavigationStack: [...workspaceData.tabNavigationStack, tab],
    });
  };

  const openSource = (source: SourceDocument) => {
    eventTracker.track('Copilot: Source opened', {
      archipelagoID: propertyArchipelagoID,
      attributeName: workspaceData?.searchFormValues?.attributeName,
      documentID: source.document_id,
      event_surface: 'Copilot',
      question: workspaceData?.searchFormValues?.question,
    });

    setTabs([
      ...tabs,
      {
        collapsable: true,
        id: source.document_id,
        label: 'Document',
        metadata: source,
        type: 'Document',
      },
    ]);

    pushTab(source.document_id);
  };

  const closeTab = (tabID: string) => {
    const tabIndex = tabs.findIndex(({ id }) => id === tabID);
    setTabs(tabs.filter((_, index) => index !== tabIndex));

    const newNavigationStack = workspaceData.tabNavigationStack.filter((id) => id !== tabID);
    setWorkspaceData({ ...workspaceData, tabNavigationStack: newNavigationStack });
  };

  const updateSearchForm = (key: string) => (value: string) => {
    setWorkspaceData((w) => ({
      ...w,
      searchFormValues: { ...w.searchFormValues, [key]: value },
    }));
  };

  const applySearch = async () => {
    const {
      searchFormValues: { propertyArchipelagoID, question: input, attributeName },
    } = workspaceData;
    const analyzeVariables = {
      attributes: [attributeName],
      format: 'ARCHIPELAGO_V2',
      input,
      organizationID: selectedOrganization.id,
      propertyArchipelagoID,
    };

    try {
      if (currentTab === 'compare') {
        await Promise.all([
          analyze({
            data: { ...analyzeVariables, config: WORKSPACE_CONFIG_MAP.workspace1 },
          }),
          analyze({
            data: { ...analyzeVariables, config: WORKSPACE_CONFIG_MAP.workspace2 },
          }),
        ]);
      } else if (['workspace1', 'workspace2', 'query'].includes(currentTab)) {
        await analyze({
          data: {
            ...analyzeVariables,
            config: WORKSPACE_CONFIG_MAP[currentTab === 'query' ? 'workspace1' : currentTab],
          },
        });
      }
    } catch (err) {
      console.error('.copilot', err);
    }
  };

  const submitCorrection = (workspaceID: string, value: string) => {
    const workspaceSettings = workspaceData[workspaceID];
    const analyzeData = workspaceData[workspaceID]?.analyzeData;
    setWorkspaceData({
      ...workspaceData,
      [workspaceID]: {
        ...workspaceSettings,
        analyzeData: {
          ...analyzeData,
          response: {
            ...analyzeData?.response,
            value,
          },
        },
      },
    });
  };

  const getAppendContent = (id: string, collapsable: boolean) => {
    switch (id) {
      case 'fullReport':
        return (
          <Badge
            color="primary"
            label={`${propertyReportsQuery?.data?.length}`}
            hidden={
              !propertyArchipelagoID ||
              propertyReportsQuery?.loading ||
              !propertyReportsQuery?.data?.length
            }
          />
        );

      case 'enrichment':
        let eigenEnrichmentJobAttributeCount = 0;
        const reportsMap = {};
        propertyReportsQuery?.data?.forEach((report) => {
          reportsMap[report.id] = report;
        });

        const property = provenanceJobData?.provenanceJobData?.properties?.[0];
        const eigenAttributes = {};
        property?.attributeComments?.forEach((comment) => {
          if (comment?.comments?.find((c) => c.message.toLowerCase().includes('eigen'))) {
            eigenAttributes[comment.attributeName] = true;
          }
        });

        property?.property?.attributeProvenance?.forEach((p) => {
          const docID = p.sourceDocuments?.[0]?.document?.id;

          if (!!reportsMap[docID] && !!eigenAttributes[p.attributeName]) {
            eigenEnrichmentJobAttributeCount++;
          }
        });

        return (
          <Badge
            color="primary"
            label={`${eigenEnrichmentJobAttributeCount}`}
            hidden={
              !propertyArchipelagoID ||
              loadingProvenanceJobData ||
              !eigenEnrichmentJobAttributeCount
            }
          />
        );
    }

    return collapsable ? (
      <ButtonIcon
        iconName="x"
        onClick={(e) => {
          e.stopPropagation();
          closeTab(id);
        }}
      />
    ) : null;
  };

  const onTabClicked = (id: string) => {
    eventTracker.track('Copilot: Tab switched', {
      archipelagoID: propertyArchipelagoID,
      attributeName: workspaceData?.searchFormValues?.attributeName,
      event_surface: 'Copilot',
      question: workspaceData?.searchFormValues?.question,
      tab: id,
    });

    pushTab(id);
  };

  return (
    <Provider
      openSource={openSource}
      workspaceData={workspaceData}
      partialUpdateWorkspaceSettings={partialUpdateWorkspaceSettings}
      updateSearchForm={updateSearchForm}
      attributeQueryMappings={{}}
      sovPropertyAttributes={{}}
      submitCorrection={submitCorrection}
      searchQuery={{ exec: applySearch, loading }}
      propertyReportsQuery={propertyReportsQuery}
      addToProvenanceJob={addToProvenanceJob}
      provenanceJobData={provenanceJobData}
      submitProvenanceEnrichment={submitProvenanceEnrichment}
      propertiesByAIDsQuery={propertiesByAIDsQuery}
      removeFromProvenanceEnrichment={removeFromProvenanceEnrichment}
      sovPropertyQuery={sovPropertyQuery}
    >
      <CopilotContainer paddingSize="l">
        <Helmet title="Copilot" />
        <EuiFlexGroup direction="column">
          <EuiFlexItem grow={false}>
            <EuiHeader>
              <EuiHeaderSection grow={false}>
                <EuiHeaderSectionItem>
                  <EuiTitle>
                    <h2>Copilot</h2>
                  </EuiTitle>
                </EuiHeaderSectionItem>
              </EuiHeaderSection>
              <EuiHeaderSection grow style={{ paddingLeft: '12px' }}>
                <EuiHeaderSectionItem>
                  <EuiTabs>
                    {tabs.map(({ id, label, collapsable }) => (
                      <EuiTab
                        key={id}
                        isSelected={id === currentTab}
                        onClick={() => {
                          onTabClicked(id);
                        }}
                        append={getAppendContent(id, collapsable)}
                      >
                        {label}
                      </EuiTab>
                    ))}
                  </EuiTabs>
                </EuiHeaderSectionItem>
              </EuiHeaderSection>
            </EuiHeader>
          </EuiFlexItem>
          <EuiFlexItem className="tabsContainer">
            <EuiPanel>
              <EuiFlexGroup>
                {!['query', 'fullReport', 'enrichment']?.includes(currentTab) && (
                  <EuiFlexItem grow={false}>
                    <ArchipelagoFormatSearch />
                  </EuiFlexItem>
                )}
                {renderTabs()}
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </CopilotContainer>
    </Provider>
  );
};
