import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLink,
  EuiTitle,
  Icon,
  Select,
  Spinner,
} from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import { COPILOT_URL } from '@app/config';
import { Property } from '@app/graphql/types';
import { useQueryState } from '@app/hooks/useQueryState';
import { useTracker } from '@app/hooks/useTracker';
import { useCopilotContext } from '../../context/context';
import { PropertyReportAnswers, PropertyReportAttribute } from '../../types';
import { AttributesTable } from '../PropertyTab/AttributesTable';
import { PropertySelect } from '../PropertyTab/PropertySelect';
import { AttributeTableRow } from '../PropertyTab/types';
import { FullPropertyTabContainer } from './FullPropertyTab.emotion';

export const FullPropertyReport: React.FC = () => {
  const {
    workspaceData: {
      searchFormValues: { propertyArchipelagoID },
    },
    attributesQuery,
    propertyReportsQuery: { data: reportsData, error, loading },
    propertiesByAIDsQuery,
    sovPropertyAttributes,
  } = useCopilotContext();
  const eventTracker = useTracker();

  const firstReportID = reportsData?.[0]?.id;
  const [currentReportID, setCurrentReportID] = useState<string>(firstReportID);
  const [currentReport, setCurrentReport] = useState<PropertyReportAnswers>(null);
  const [qs, setQueryState] = useQueryState();
  const initialAttribute = qs.get('attributeName');

  useEffect(() => {
    setCurrentReportID(firstReportID);
  }, [firstReportID]);

  const onReportChange = (reportID: string) => {
    setCurrentReportID(reportID);
    eventTracker.track('Copilot: Eigen report selected', {
      archipelagoID: propertyArchipelagoID,
      event_surface: 'Copilot',
      reportID,
    });
  };

  const {
    data: propertyData,
    loading: loadingProperty,
    error: propertyError,
    refetch,
  } = propertiesByAIDsQuery;

  const [, rateReport] = useAxios(
    { method: 'PUT', url: `${COPILOT_URL}/reports/${currentReportID}/rate` },
    { manual: true },
  );

  const [{ data, loading: loadingReport, error: reportError }] = useAxios<PropertyReportAnswers>(
    {
      url: `${COPILOT_URL}/properties/reports/${currentReportID}`,
    },
    { useCache: false },
  );

  useEffect(() => {
    setCurrentReport(data);
  }, [data?.document_id]);

  const property = propertyData?.propertiesByAiDs?.properties?.[0];

  const items: Array<AttributeTableRow> =
    currentReport?.answers?.map(
      ({
        id,
        answer,
        finalAnswer,
        suggestedAnswer,
        attributeName,
        explanation,
        feedback,
        expandedByDefault,
      }) => ({
        copilotValue: answer,
        finalValue: finalAnswer,
        suggestedValue: suggestedAnswer,
        currentValue: property?.[attributeName] || '(Blank)',
        displayName: attributesQuery?.dict?.[attributeName]?.metadata?.displayName,
        expandedByDefault,
        explanation,
        id,
        name: attributeName,
        rating: feedback,
        sources: [{ document_id: currentReport.document_id, sources: [] }],
        sovValue: sovPropertyAttributes?.[attributeName] || '(Blank)',
      }),
    ) || [];

  const overrideAnswerAttributes = (rowID, payload: Partial<PropertyReportAttribute>) => {
    const newAnswers = [...(currentReport?.answers || [])];
    const newAnswerIndex = newAnswers.findIndex(({ id }) => id === rowID);
    if (newAnswerIndex >= 0) {
      newAnswers[newAnswerIndex] = {
        ...newAnswers[newAnswerIndex],
        ...payload,
      };
      setCurrentReport({ ...currentReport, answers: newAnswers });
    }
  };

  const onRating = async (rowID, rate, finalAnswer) => {
    overrideAnswerAttributes(rowID, { expandedByDefault: true, feedback: rate, finalAnswer });

    await rateReport({
      data: {
        attributeName: rowID,
        feedback: rate,
        finalAnswer,
      },
    });
  };

  const renderBody = () => {
    if (!propertyArchipelagoID) {
      return (
        <EuiEmptyPrompt
          iconType={() => <Icon size="xxl" name="property" />}
          iconColor="subdued"
          title={<h4>Select a property to get started</h4>}
        />
      );
    }

    if (loading || loadingReport || loadingProperty) {
      return <Spinner />;
    }

    if (!!error || !reportsData?.length) {
      return <ErrorMessage title="No reports available for this property" />;
    }

    if (propertyError) {
      return <ErrorMessage title="Could not get property data" />;
    }

    if (reportError) {
      return <ErrorMessage title="Could not get report" />;
    }

    if (!currentReport) {
      return (
        <EuiEmptyPrompt
          iconType={() => <Icon size="xxl" name="property" />}
          iconColor="subdued"
          title={<h4>Select a report to get started</h4>}
        />
      );
    }

    return (
      <>
        <EuiFlexItem grow={false}>
          <EuiTitle size="xs">
            <h4>Classification: {currentReport.classification || 'Unknown'}</h4>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiTitle size="xs">
            <h4>Document ID: {currentReport.document_id}</h4>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
          <EuiLink href={currentReport?.httpURL} target="_blank">
            Download source document
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
          <EuiLink href={currentReport?.reportHttpURL} target="_blank">
            Download report CSV
          </EuiLink>
        </EuiFlexItem>
        <AttributesTable
          items={items}
          property={property as Property}
          onPropertyUpdate={refetch}
          onCorrection={() => {}}
          onRating={onRating}
          onRowExpanded={(row) => {
            eventTracker.track('Copilot: Property report - row opened', {
              archipelagoID: propertyArchipelagoID,
              attributeName: row.name,
              event_surface: 'Copilot',
              reportRowID: row.id,
            });
          }}
          onRowClosed={(row) => {
            eventTracker.track('Copilot: Property report - row closed', {
              archipelagoID: propertyArchipelagoID,
              attributeName: row.name,
              event_surface: 'Copilot',
              reportRowID: row.id,
            });
            overrideAnswerAttributes(row.id, { expandedByDefault: false });
          }}
        />
      </>
    );
  };

  const options = reportsData?.map((answer) => ({
    label: answer.filename,
    value: answer.id,
  }));

  return (
    <FullPropertyTabContainer>
      <EuiFlexGroup className="property-tab-content" direction="column">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} className="property-select">
              <EuiFormRow label="Select property">
                <PropertySelect
                  value={propertyArchipelagoID}
                  handleChange={(archipelagoID) =>
                    setQueryState({ archipelagoID, attributeName: initialAttribute })
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            {!!propertyArchipelagoID && !loadingProperty && !!reportsData?.length && (
              <EuiFlexItem grow={false} className="property-select">
                <EuiFormRow label="Select document">
                  <Select value={currentReportID} onChange={onReportChange} options={options} />
                </EuiFormRow>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className="report-body">{renderBody()}</EuiFlexItem>
      </EuiFlexGroup>
    </FullPropertyTabContainer>
  );
};
