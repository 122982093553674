import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { useToast } from 'ui';
import { useSelection } from '@app/contexts/SelectionContext/SelectionContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useApplyDisposePropertiesJobInputMutation } from '@app/graphql/jobs/mutations/__generated__/applyDisposePropertiesJob.generated';
import { useTracker } from '@app/hooks/useTracker';
import { AccordionTable } from '../AccordionTable/AccordionTable';
import { DatePickerField } from '../Form/DatePickerField/DatePickerField';
import { ModalWithButtons } from '../ModalWithButtons/ModalWithButtons';
import { sendMixpanelEvent } from '../PropertiesDataGrid/utils';
import { StyledDescription } from './DisposePropertyModal.emotion';

const tableKeys = ['locationName', 'locationId', 'archipelagoId'];

const tableKeysToDisplayName = {
  archipelagoId: 'Archipelago ID',
  locationId: 'Client ID',
  locationName: 'Building name',
};

export const DisposePropertyModal = ({ onClose, refetch, refetchGroups }) => {
  const { selectedOrganization } = useUserSession();
  const toast = useToast();
  const orgName = selectedOrganization.name;
  const { selectedRows, updateSelectedRows } = useSelection();
  const jobsApolloClient = useJobsApolloClient();
  const effectiveSelectedRows = Object.values(selectedRows);

  const { stream } = useStreamContext();
  const tracker = useTracker();

  const label =
    effectiveSelectedRows.length > 1
      ? `Your properties (${effectiveSelectedRows.length}) have been disposed.`
      : 'Your property has been disposed.';

  const [applyDisposePropertiesJobInputMutation, { loading: isDisposing }] =
    useApplyDisposePropertiesJobInputMutation({
      client: jobsApolloClient,
      onCompleted: () => {
        refetch();
        updateSelectedRows({ action: 'clear' });
        toast({ label, title: 'Success' });
      },
      onError: (e) => {
        const errorLabel =
          effectiveSelectedRows.length > 1
            ? ` ${
                e.message.includes('already being edited')
                  ? 'The selected properties are currently being edited in another job and cannot be disposed. Please finish the in progress job before trying again.'
                  : `There was an error disposing properties (${effectiveSelectedRows.length})`
              }`
            : `  ${
                e.message.includes('already being edited')
                  ? 'The selected property is currently being edited in another job and cannot be disposed. Please finish the in progress job before trying again.'
                  : 'There was an error disposing the property.'
              }`;
        toast({ label: errorLabel, title: 'Error', type: 'danger' });
      },
    });

  const formMethods = useForm({
    mode: 'all',
  });

  const { handleSubmit } = formMethods;

  const [disposePropertiesJobInput, setDisposePropertiesJobInput] = useState(
    effectiveSelectedRows.reduce(
      (acc, row) => ({
        ...acc,
        [row.archipelagoId]: {
          disposalDate: '',
          propertyID: row.archipelagoId,
        },
      }),
      {},
    ),
  );

  const header =
    effectiveSelectedRows.length > 1
      ? 'Dispose this property?'
      : `Dispose ${effectiveSelectedRows.length} properties?`;

  return (
    <FormProvider {...formMethods}>
      <ModalWithButtons
        onClose={() => {
          sendMixpanelEvent(tracker, 'Cancel dispose properties', stream);
          onClose();
        }}
        header={header}
        subtext="Disposing these properties means they have been sold or are no longer covered by insurance. To dispose, please add the disposal date."
        buttonActionLabel="Dispose"
        isLoading={isDisposing}
        onClick={handleSubmit(async () => {
          sendMixpanelEvent(tracker, 'Dispose properties', stream, {
            archipelagoIds: effectiveSelectedRows.map((property) => property.archipelagoId),
          });
          await applyDisposePropertiesJobInputMutation({
            variables: {
              input: {
                orgName,
                properties: Object.values(disposePropertiesJobInput),
              },
            },
          });
          onClose();
        })}
      >
        {effectiveSelectedRows.map((row) => {
          const title = `${row.streetAddress}, ${row.city}, ${row.postalCode}, ${row.country}`;
          return (
            <AccordionTable
              id={row.archipelagoId}
              title={title}
              tables={tableKeys.map((key) => ({
                label: tableKeysToDisplayName[key],
                value: row[key],
              }))}
            >
              <StyledDescription>Disposal date</StyledDescription>
              <StyledDescription>
                <DatePickerField
                  required={false}
                  id={row.archipelagoId}
                  selected={
                    disposePropertiesJobInput[row.archipelagoId]?.disposalDate
                      ? moment(disposePropertiesJobInput[row.archipelagoId]?.disposalDate)
                      : null
                  }
                  isLoading={isDisposing}
                  onChange={(date) => {
                    sendMixpanelEvent(tracker, 'Set disposal date', stream, {
                      archipelagoId: row.archipelagoId,
                    });
                    setDisposePropertiesJobInput((prev) => ({
                      ...prev,
                      [row.archipelagoId]: {
                        disposalDate: date ? date.format('YYYY-MM-DD') : '',
                        propertyID: row.archipelagoId,
                      },
                    }));
                  }}
                />
              </StyledDescription>
            </AccordionTable>
          );
        })}
      </ModalWithButtons>
    </FormProvider>
  );
};
